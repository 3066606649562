// dropdown-select-autocomplete controller
// =======================================

import ApplicationController from '../application_controller'

let debounce = require('lodash/debounce')

export default class DropdownSelectAutocomplete extends ApplicationController {

  connect(){
    super.connect()
    this.url = this.data.get('url')
    this.search_input = this.element.querySelector('input[role="textbox"]')
    this.select = this.element.querySelector('select')

    this._onInput = debounce((e) => this.onInput(e), 700).bind(this)
    this.element.addEventListener('input', this._onInput, true)
  }

  disconnect(){
    this.element.removeEventListener('input', this._onInput, true)
    super.disconnect()
  }

  onInput(e){
    let search_text = e.target.value
    let search_url = this.url.includes('?') ?
      `${this.url}&search=${escape(search_text)}` :
      `${this.url}?search=${escape(search_text)}`
    console.log('search value: %o (url: %o)', search_text, search_url)
    fetch(search_url)
      .then((response) => (response.json()))
      .then((json) => {
        console.log('search for %o: %o', search_text, json)
        this.updateSelectList(json, search_text)
      })
  }

  updateSelectList(json, search_text){
    for (let option of this.select.querySelectorAll('option')) {
      if (option.value != '') {
        option.parentElement.removeChild(option)
      }
    }
    for (let item of json) {
      let option = this.select.ownerDocument.createElement('option')
      option.setAttribute('value', item.id)
      option.textContent = item.value
      this.select.appendChild(option)
    }
    $(this.select).multiselect('rebuild')
    this.element.querySelector('.multiselect-search').value = search_text
    this.element.querySelector('.multiselect-search').focus()
  }

}


