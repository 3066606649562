import ApplicationController from '../application_controller'
import * as UiBibz from '../../src/ui_bibz'

export default class UiBibzInitController extends ApplicationController {

  connect(){
    UiBibz.init(this.element)
  }

}

