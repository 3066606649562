import ApplicationController from '../application_controller'

export default class PageController extends ApplicationController {

  connect(){
    super.connect()
    this.addAction('turbo:load@document', 'onTurboLoad')
    //this.addAction('turbo:before-visit@document', 'onTurboBeforeVisit')
    //if(!window.turboBlacklist) window.turboBlacklist = []
  }

  onTurboBeforeVisit(e){
    //let url = e.detail.url
    //if(window.turboBlacklist.includes(url)) {
    //  e.preventDefault()
    //}
    //this._makeProfilerPermanent()
    //window.MiniProfiler.pageTransition()
  }

  onTurboLoad(e){
    //this._makeProfilerPermanent()
  }

  openFormPopup(e){
    e.preventDefault()
    this.openMakeForm(e.target.closest('a').href, (form) => {})
  }

  //_makeProfilerPermanent(){
  //  for(let profilerResults of this.document.querySelectorAll('body > profiler-results')) {
  //    profilerResults.dataset.turboPermanent = "true"
  //  }
  //}
}
