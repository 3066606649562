import ApplicationController from '../application_controller'
import CurrentViewChannel from './../../channels/current_view_channel.js'

const debug = window.lcas_debug?.includes('current-view')

export default class CurrentViewController extends ApplicationController {

  static values = {
    refId: String,
    typeClass: String,
    specificTypeClass: String,
    waitChanges: {type: Boolean, default: true},
    alwaysReload: {type: Boolean, default: true}
  }

  connect(){
    super.connect()
    if (debug) console.log('current-view: wait for changes to %s', this.refIdValue)
    this.sendEvent()

    if (this.waitChangesValue) {
      let params = {}
      if (this.hasTypeClassValue) {
        params['container_ref_id'] = this.refIdValue
        params['container_class'] = this.typeClassValue
      } else {
        params['container_class'] = this.refIdValue
      }
      this.channel = new CurrentViewChannel(this, params)
      this.notification = document.createElement('div')
      this.notification.classList.add('c-current-view-changed')
      this.notification.textContent = I18n.t('front.controllers.current_view.view_changed')
      this.notification.addEventListener('click', e => this.refreshCurrentView())
    }

    this.addAction('focus@window', 'onFocus')
    this.addAction('blur@window', 'onBlur')
    this.addAction('form-open@document', 'onFormOpen')
  }

  disconnect(){
    super.disconnect()
    if (this.channel) this.channel.close()
    this.channel = undefined
  }

  get currentRefId(){
    return this.refIdValue
  }

  get currentTypeClass(){
    return this.typeClassValue
  }

  get currentSpecificTypeClass(){
    return this.specificTypeClassValue
  }

  get currentItem(){
    if (this.currentRefId == null) return null
    return (document.querySelector(`input[value="${this.currentRefId}"]`)?.labels || [])[0]
  }

  currentViewChanged(data){
    if (debug) console.log('current-view: changed to %s %s (data: %o ; currentItem: %o)', this.currentRefId, this.automaticReload ? '(auto reload)' : '(notif)', data, this.currentItem)
    this.sendEvent()
    if (this.alwaysReloadValue || this.automaticReload) {
      this.refreshCurrentView()
    } else {
      this.element.appendChild(this.notification)
    }
  }

  sendEvent(){
    document.body.dataset.currentId = this.currentRefId
    let ev = new CustomEvent('current-view-changed', {detail: {ref_id: this.currentRefId, item: this.currentItem}})
    if (debug) console.log('current-view-changed %o (detail: %o ; currentItem: %o)', ev, ev.detail, this.currentItem)
    document.dispatchEvent(ev)
  }

  refreshCurrentView(){
    window.turboReload()
  }

  onFocus() {
    this.focused = true
    this.automaticReload = false
  }

  onBlur() {
    this.focused = false
  }

  onFormOpen(){
    this.automaticReload = true
  }

}

