import consumer from './consumer'
import * as eventSource from './event_source.js'
import * as longPoll from './long_poll.js'

export let enable_action_cable = window.lcas_actioncable_enable ?? true
export let enable_event_source = window.lcas_eventsource_enable ?? true
export let enable_long_poll = window.lcas_longpoll_enable ?? true
export let event_source_url = window.lcas_eventsource_url ?? `http://${document.location.hostname}:8080/events`

export function subscribe_event_source(subscription, mixin) {
  return eventSource.createChannel(event_source_url, JSON.stringify(subscription), mixin)
}

export function subscribe_long_poll(subscription, mixin) {
  return longPoll.createChannel(event_source_url, JSON.stringify(subscription), mixin)
}

export function subscribe_action_cable(subscription, mixin) {
  return consumer.subscriptions.create(subscription, mixin)
}

export const event_source_mixin = {
  close(){
    this.closeEvent()
  },
}

export const action_cable_mixin = {
  close(){
    consumer.subscriptions.remove(this)
  },
}

export class MultiChannel {
  constructor(channels) {
    this.channels = channels || []
  }

  close() {
    for (let c of this.channels) {
      c.close()
    }
    this.channels = []
  }

  close_one(chan) {
    this.channels = this.channels.filter(c => c != chan)
    chan.close()
  }

  add_channel(chan, cb){
    if (!chan) throw new Error('Missing channel')
    this.channels = [...this.channels, chan]
    if (cb) return cb(chan)
  }

  get() {
    return this.channels[0]
  }
}

export function subscribe_one(subscription, mixin) {
  if (enable_long_poll) {
    return subscribe_long_poll(subscription, {...event_source_mixin, ...mixin})
  } else if (enable_action_cable) {
    return subscribe_event_source(subscription, {...event_source_mixin, ...mixin})
  } else if (enable_event_source) {
    return subscribe_action_cable(subscription, {...action_cable_mixin, ...mixin})
  }
}

export default function subscribe_all(subscription, mixin, cb){
  let multi = new MultiChannel()
  let long_poll, event_source, action_cable

  if (enable_long_poll) {
    // Subscribe to long polling
    long_poll = subscribe_long_poll(subscription, {...event_source_mixin, ...mixin})
    multi.add_channel(long_poll, cb)
  } else if (enable_event_source) {
    // Subscribe to EventSource
    event_source = subscribe_event_source(subscription, {...event_source_mixin, ...mixin})
    multi.add_channel(event_source, cb)
  }

  // Subscribe to ActionCable
  if (enable_action_cable) {
    action_cable = subscribe_action_cable(subscription, {...action_cable_mixin, ...mixin})
    multi.add_channel(action_cable, cb)
  }

  if (action_cable && (event_source || long_poll)) {
    // Disconnects ActionCable if not connected for 60 seconds
    let timeout = setTimeout(() => {
      console.log('ActionCable cannot connect, stop connecting')
      multi.close_one(action_cable)
    }, 60000)

    // Disconnect EventSource/LongPoll if ActionCable connects
    let interval = setInterval(() => {
      if (consumer.connection.isOpen()) {
        console.log('ActionCable is connected: disconnect EventSource')
        if (event_source) multi.close_one(event_source)
        if (long_poll) multi.close_one(long_poll)
        clearInterval(interval)
        clearTimeout(timeout)
      }
    }, 1000)
  }

  return multi
}
