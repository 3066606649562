import ApplicationController from '../application_controller'

// This must be the exact same as in ui-bibz-js
//import bootstrap from 'ui-bibz-js/node_modules/bootstrap/dist/js/bootstrap.bundle'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'

export default class UiDatabaseLibraryController extends ApplicationController {

  static values = {
    paramsFiltersActive: String,
  }

  static targets = [
    'filtersButton', 'enableFiltersButton', 'resetFiltersButton', 'searchField'
  ]

  connect(){
    super.connect()
    if (this.hasSearchFieldTarget) this.addAction('input', 'setFiltersButtonEnabledState', this.searchFieldTarget)
    if (this.hasEnableFiltersButtonTarget) this.addAction('click', 'enableFilters', this.enableFiltersButtonTarget)
    if (this.hasResetFiltersButtonTarget) this.addAction('click', 'resetFilters', this.resetFiltersButtonTarget)

    this.initLibraryFilter()
    this.setFiltersButtonEnabledState()
  }

  initLibraryFilter(){
    let dropdownEl = this.element.querySelector('.surround-search button[data-bs-toggle=dropdown]')
    if (dropdownEl) { // filter is only for modules
      let dropdown = bootstrap.Dropdown.getOrCreateInstance(dropdownEl)
      this.addAction('hide.bs.dropdown', 'preventCloseFilters', dropdownEl)
    }
  }

  setFiltersButtonEnabledState(){
    if (!this.hasSearchFieldTarget) return

    if (this.hasEnableFiltersButtonTarget) this.enableFiltersButtonTarget.disabled = !this.searchFieldTarget.value
    const dropdownEl = this.element.querySelector('.surround-search button[data-bs-toggle=dropdown]')
    if (dropdownEl) dropdownEl.disabled = !this.searchFieldTarget.value
  }

  preventCloseFilters(e) {
    const dropdown = bootstrap.Dropdown.getInstance(e.target)
    const menu = dropdown._menu
    const originalTarget = e.explicitOriginalTarget || e.clickEvent?.srcElement
    if (menu.contains(originalTarget)) {
      e.preventDefault()
    }
  }

  enableFilters(e){
    const form = e.target.closest('form')
    const activeInput = form.querySelector(`[name="${this.paramsFiltersActiveValue}"]`)
    activeInput.value = '1'
  }

  resetFilters(e){
    const form = e.target.closest('form')

    for (const input of form.querySelectorAll('input[name*="[filters]"')) {
      input.value = ''
    }
    for (const select of form.querySelectorAll('select[id*="search"]')) {
      select.value = ''
    }
  }
}
