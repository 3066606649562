import ApplicationController from '../application_controller'

export default class AdminOverviewController extends ApplicationController {

  connect(){
    super.connect()
    this.addAction('keydown', 'shortcuts')
  }

  shortcuts(e){
    // TODO
  }

}
