import ToolbarBaseController from '../utils/toolbar_base_controller'
import DesignActions from '../../src/design/actions.js'
import SelectionMixin from '../concerns/selection_mixin'

export default class DesignLibraryToolbarController extends ToolbarBaseController {
  static targets = ['compareItem']

  connect(){
    super.connect()

    let actions = new DesignActions(this, [], {toolbar: 'dbnode'})
    actions.updateActions({
      'compareDbItem': this.hasCompareItemTarget ? this.compareItemTarget : null,
    })

    this.bindActions(actions)
  }

}
Object.assign(DesignLibraryToolbarController.prototype, SelectionMixin)
