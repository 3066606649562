import BaseActions from '../base_actions.js'
import Modal from '../lib/modal.js'


const debug = window.lcas_debug?.includes('admin-actions') // ?debug[admin-actions]=1

export default class AdminActions extends BaseActions {

  constructor(controller, items, context, currentItem){
    super(controller, items, context, currentItem)
  }

  editLine(context){
    let label = document.querySelector('input[name=node]:checked + label')
    let item_id = document.querySelector('input[name=node]:checked').value
    this.helperEditLine(label, {
      title: I18n.t('admin.actions.edit_line.name'),
      frame_id: `edit-line-${item_id}`,
      frame_url: Routes.edit_line_admin_user_path(
        item_id,
        {frame: `edit-line-${item_id}`})
    })
  }

  editItem(context){
    this.openForm(this._buildAdminUrl(this.item.dataset.type, this.item.value, 'edit'))
  }

  editManager(context){
    this.openForm(this._buildAdminMultiUrl('user', this.item.value.split(), 'edit_manager'))
  }

  editExpirationDate(context){
    this.openForm(this._buildAdminMultiUrl('user', this.item.value.split(), 'edit_expiration_date'))
  }

  duplicate(context){
    this.openForm(Routes.duplicate_admin_user_path(this.item.value.split()))
  }

  async deleteItem(context){
    const values = Array.from(this.items, e => e.value)
    await this._deleteItemImplem(context, values, async json => {
      if (debug || this.debugOnce) console.log(json)
      if (json.num_items > json.num_items_by_type['Cs::Project']) {
        const m = new Modal({text: I18n.t('project.delete_denied')})
        await m.enable()
        await m.interacted()
        return false
      }
    })
  }


  addUser(context){
    this.openForm(Routes.new_admin_user_path())
  }

  addDepartment(context){
    this.openForm(Routes.new_admin_department_path())
  }

  addCompany(context){
    this.openForm(Routes.new_admin_company_path())
  }

  addInstance(context){
    this.openForm(Routes.new_admin_instance_path())
  }

  updateAction(actname,action){
    this.disable(action)

    if (this.controller.debug) {
      console.log('%s/updateAction(%s) currentItem = %o', this.controller.identifier, actname, this.currentItem)
    }

    switch (actname) {
    case 'addUser': return this.show(action, true, true, true)
    case 'addInstance': return this.show(action, true, true, true)
    case 'addDepartment': return this.show(action, true, true, true)
    case 'addCompany': return this.show(action, true, true, true)
    case 'addCommunication': return this.show(action, true, true, true)
    }
    if (this.items.length == 1) {
      switch (actname){
      case 'editLine': return this.show(action, true, true, true)
      case 'editItem': return this.show(action, true, true, true)
      case 'duplicate': return this.show(action, true, true, true)
      case 'editManager': return this.show(action, true, true, true)
      case 'editExpirationDate': return this.show(action, true, true, true)
      case 'deleteItem': return this.show(action, true, true, true)
      }

    }
  }


}