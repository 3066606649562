import DesignController from '../design_controller'

export default class extends DesignController {

  connect(){
    super.connect()
  }

}

