import ApplicationController from '../application_controller'

export default class ComparisonController extends ApplicationController {

  static targets = [
    'itemSearchResults',
    'search',
    'editForm',
    'submitButton'
  ]

  connect() {
    super.connect()

    this.searchUrl = this.data.get('search-url')
    this.searchField = this.searchTarget.querySelector('[type=search][name=item_search]')

    this.addAction('click', 'clearCaseStudySearch', this.searchTarget.querySelector('button.clear-search'))
    this.addAction('click', 'treeClicked', this.itemSearchResultsTarget)
  }

  treeClicked(e){
    let label = e.target.closest('label')
    if (label?.dataset?.type == 'case-study') {
      return this.itemClicked(e)
    }
  }

  itemClicked(e){
    // Do not prevent event if click is within form
    if (e.target.closest('.comparison-items-form')){
      return
    }

    e.preventDefault(true)

    // Do nothing in case the click is not on the item text or on the badges
    // if (!e.target.closest('a') && !e.target.closest('.c-treeview__badges')) return

    let label = e.target.closest('label')

    if (label.dataset.disabled === 'true') {
      return
    }

    let badges = label.querySelector('.c-treeview__badges')
    let form = label.querySelector('form')

    // Do nothing in case the form is already shown
    if (form && !form.parentElement.classList.contains('hidden')) return

    if (!form) {
      label.appendChild(this.editFormTarget.content.cloneNode(true))
      form = label.querySelector('form')
    }
    form.querySelector('input[type="number"]').value = label.dataset.ratio || 1

    this.addAction('click', 'cancelItemFrom', form.querySelector('a.cancel'))

    if (badges) badges.classList.add('hidden')
    form.parentElement.classList.remove('hidden')

    if (label.getAttribute('data-type') === 'case-study') {
      const input = document.createElement('input')
      const input2 = document.createElement('input')
      const input3 = document.createElement('input')
      const index = this.editFormTarget.getElementsByTagName('input').length / 3
      input.setAttribute('name', `comparison[items_comparisons_attributes][${index}][itemable_id]`)
      input.setAttribute('value', label.getAttribute('data-ref-id'))
      input2.setAttribute('name', `comparison[items_comparisons_attributes][${index}][itemable_type]`)
      input2.setAttribute('value', 'Cs::CaseStudy')
      input3.setAttribute('name', `comparison[items_comparisons_attributes][${index}][ratio]`)
      input3.setAttribute('value', label.dataset.ratio || 1)
      this.editFormTarget.appendChild(input)
      this.editFormTarget.appendChild(input2)
      this.editFormTarget.appendChild(input3)

      form.addEventListener('reset', () => {
        this.editFormTarget.removeChild(input)
        this.editFormTarget.removeChild(input2)
        this.editFormTarget.removeChild(input3)
        const index = this.editFormTarget.getElementsByTagName('input').length / 3
        if (index === 0) this.submitButtonTarget.classList.add('disabled')
      })

      form.querySelector('input[type="number"]').onchange = (e) => {
        input3.value = e.target.value
        this.checkRatio(e)
      }

      this.submitButtonTarget.classList.remove('disabled')
    }

    setTimeout(() => form.querySelector('input').focus(), 50)
  }

  clearCaseStudySearch(e){
    this.searchField.value = ''
  }

  cancelItemFrom(e) {
    e.preventDefault()
    let form = e.target.closest('form')
    let badges = form.closest('label').querySelector('.c-treeview__badges')
    form.reset()
    form.parentElement.classList.add('hidden')
    if (badges) badges.classList.remove('hidden')
  }
}
