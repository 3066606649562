import ApplicationController from './application_controller'
import SelectionMixin from './concerns/selection_mixin'

export default class ProjectController extends ApplicationController {

  getRootItem(){
    let interfaceController = this.getController('interface')
    return JSON.parse(interfaceController.data.get('root-item'))
  }

}

Object.assign(ProjectController.prototype, SelectionMixin)
