import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [
    'handle',
    'element'
  ]

  connect(){
    super.connect()
    if (this.elementTarget) {
      if (this.handleTarget) $(this.elementTarget).draggable({handle: this.handleTarget})
    } else {
      if (this.handleTarget) $(this.element).draggable({handle: this.handleTarget})
    }
  }

}
