import ContextMenuBaseController from '../utils/context_menu_base_controller'
import DesignActions from '../../src/design/actions.js'

const legacy_menu = false

export default class extends ContextMenuBaseController {
  static targets = ['libraryAdd', 'libraryInfo', 'libraryComparison', 'libraryReplaceItem']

  connect() {
    super.connect()
    this.names = ['dbnode']

    const actions = new DesignActions(this, [], {menu: 'dbnode'})
    actions.updateActions({
      'replaceDbItem': this.libraryReplaceItemTarget,
      'compareDbItem': this.hasLibraryComparisonTarget ? this.libraryComparisonTarget : null,
      'infoDbItem':    this.libraryInfoTarget,
      'addDbItem':     this.libraryAddTarget,
    })

    this.bindActions(actions)
  }

  filterContextMenuClick(e) {
    return e.detail.name === 'dbnode'
  }

  getSelectedItems(){
    return super.getSelectedItems('dbnode')
  }

  showMenu(e){
    if (!legacy_menu) return

    let label = e.target.tagName == 'LABEL' ? e.target : e.target.closest('label')
    if (label == null) { return }

    if (this.isCategory(label.getAttribute('data-type'))) return false

    if (!this._handleSelection(e, label)) return false
    return super.showMenu(e)
  }

  isCategory(name){
    return name.search('Category') != -1
  }

  updateActions(){
    let selectedItems = this.getSelectedItems()

    if (selectedItems.length > 0) {
      let values = Array.prototype.map.call(selectedItems, e => e.value)
      let node = selectedItems[0]
      let id = node.getAttribute('data-id')
      let type = node.getAttribute('data-type').replace('Db::', '')

      let infoUrl = `/database/${ _.pluralize(type.toLowerCase()) }/${ id }`
      let addUrl = node.nextElementSibling.querySelector('a').getAttribute('href')

      this.libraryInfoTarget.setAttribute('href', infoUrl)
    }
  }

  handleShortcuts(e){
    this.updateActions()

    return false
  }

  clickOnDbLink(){
    let selectedItems = this.getSelectedItems()
    //selectedItems[0].nextElementSibling.querySelector('a').click()
    if (selectedItems.length > 0) {
      // Fucking tempo to use click
      setTimeout(function(){
        selectedItems[0].nextElementSibling.querySelector('a').click()
      }, 1)
    }
  }
}
