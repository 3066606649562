import ApplicationController from './../application_controller'
import ProjectImporter from './../../src/project/importer'

export default class extends ApplicationController {

  import(e){
    if (e) e.preventDefault()
    new ProjectImporter(this.parent_ref_id, this.reload).import()
  }

  get reload(){
    return this.data.get('reload') == 'true'
  }

  get parent_ref_id() {
    return this.data.get('parent-ref-id')
  }

}

