import AdminController from '../admin_controller'

export default class CommunicationLayoutController extends AdminController {

  static values = {
    treeMinWidth: Number,
    detailsMinWidth: Number
  }

  connect(){
    super.connect()

    this.details_min_width = this.data.get('details-min-width') || 50
    this.details = this.element.querySelector('.c-communication-layout__details')

    this.initDetailsWidth()
    this.resizeDetails()
  }


  //
  // Details
  //

  initDetailsWidth(){
    let width = sessionStorage.getItem(this.getStorageName('details-width'))
    if (width != null) this.details.style.width = `${ width }px`
  }

  resizeDetails(){
    $(this.details).resizable({
      minWidth: this.details_min_width,
      handles: 'w',
      resize: (ev, ui) => {
        ui.element[0].style.left = null
      },
      stop: (u, ui) => {
        sessionStorage.setItem(this.getStorageName('details-width'), ui.size.width)
      }
    })
    let handle = this.findResizableHandle(this.details)
    this.addAction('mouseover', 'showDetailsHandle', handle)
    this.addAction('mouseout', 'hideDetailsHandle', handle)
  }

  //
  // Handles
  //
  showDetailsHandle(){
    this.element.classList.add('c-communication-layout--details-resizing')
  }

  hideDetailsHandle(){
    this.element.classList.remove('c-communication-layout--details-resizing')
  }


  findResizableHandle(elem) {
    let handles = elem.querySelectorAll('.ui-resizable-handle')
    return handles[handles.length - 1]
  }

}
