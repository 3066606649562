import * as spin from '../../src/lib/top_left_spinner.js'

// If the reload should be automatic (true) or if we should display a banner
// inviting the user to reload (false),
const automatic_reload = true

function genId(){
  let array = new Uint8Array(8)
  crypto.getRandomValues(array)
  return btoa(array)
}

export let debug = window.lcas_debug?.includes('longpoll') // ?debug[longpoll]=1
export let timeout = 15
export let createSpinner = true

function queue_long_poll(url, signal, cb, timeout) {
  setTimeout(() => long_poll(url, signal, cb), timeout || 0)
}

async function long_poll(url, signal, cb) {
  if (debug) console.log('long-poll %s', url)
  const start_time = new Date()
  let success = false

  if (createSpinner) spin.spin(timeout)

  try {
    const response = await fetch(`${url}?poll-queue=32&timeout=${timeout}`, {
      signal,
      credentials: 'include',
      method: 'GET',
      headers: { 'accept': 'application/json' }
    })

    const json = await response.json()
    success = json && ! json.error
    if (debug) console.log('long-poll %s response: %o', url, json)
    cb(json)
  } finally {
    if (createSpinner) spin.stopSpin()
    if (!signal.aborted) {
      let timeout = 5000 // 5 seconds between each failed attempt
      if (success) {
        timeout = 0
      } else {
        timeout -= start_time - (new Date())
        if (timeout < 0) timeout = 0
      }
      queue_long_poll(url, signal, cb, timeout)
    }
  }
}

let running = {}

export function run(event_source_url) {
  if (is_running(event_source_url)) return running[event_source_url]

  const url = `${event_source_url.replace(/\/$/, '')}/${genId()}`
  const abort = new AbortController()

  console.log('long-poll run %s', url)

  if (createSpinner) spin.createSpinnerElement()

  const stream = {
    url,
    abort,
    callbacks: []
  }

  queue_long_poll(url, abort.signal, (msg) => {
    if (!msg.messages && msg.message) msg.messages = [msg.message]
    if (!msg.messages) msg.messages = [] // messages might be null if there is no message
    for (const cb of stream.callbacks) {
      cb(msg)
    }
  })

  running[event_source_url] = stream
  return stream
}

export function start(event_source_url, cb) {
  running[event_source_url].callbacks.push(cb)
  return running[event_source_url]
}

export function stop(event_source_url, callback, { keep_stream = false }) {
  const stream = running[event_source_url]
  stream.callbacks = stream.callbacks.filter(cb => (cb != callback))
  if (!keep_stream && stream.callbacks.length == 0) {
    stream.abort.abort()
    delete running[event_source_url]
    console.log('long-poll stop %s', stream.url)
  }
  return stream
}

export function is_running(event_source_url) {
  return !! running[event_source_url]
}

async function send_subscription_request(event_source_url, identifier, cb) {
  const {url, abort} = run(event_source_url)

  const request = [
    {subscribe: true, identifier}
  ]

  try {

    const response = await fetch(`${url}?poll-queue=32`, {
      signal: abort.signal,
      credentials: 'include',
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(request)
    })

    const json = await response.json()

    if (debug) console.log('long-poll %s POST response: %o to request %o', url, json, request)
    if (json.error || json[0].error) {
      console.error('long-poll %s Error in POST response: %o (request: %o)', url, json, request)
      inviteToReload()
    }

    const chan_id = json[0].chan_id
    const session_id = json[0].session_id

    const handler = (resp) => {
      if (debug) console.log('long-poll [%s %s] Received %o', session_id, chan_id, resp)
      if (resp.session_id != session_id || (resp.channels && !resp.channels.includes(chan_id))) {
        let explanation = []
        if (resp.session_id != session_id) {
          explanation.push(`session id changed from ${session_id} to ${resp.session_id}`)
        }
        if (resp.channels && !resp.channels.includes(chan_id)) {
          explanation.push(`subscription to channel ${chan_id} was lost`)
        }
        console.warn('Unsubscribed from %o, need to re-subscribe (some messages may be lost): %s', identifier, explanation.join(', '))
        stop(event_source_url, handler, {keep_stream: true})
        send_subscription_request(event_source_url, identifier, cb)
        inviteToReload()
      } else {
        for (const message of resp.messages) {
          if (message.chan_id != chan_id) continue
          cb(message, chan_id, resp.session_id)
        }
      }
    }

    start(event_source_url, handler)

  } catch (error) {
    console.error('long-poll %s POST error: %o', url, error)
  }
}

export function subscribe(event_source_url, channel, cb) {
  const {url, abort} = run(event_source_url)
  const identifier = JSON.parse(channel)

  send_subscription_request(event_source_url, identifier, cb)

  return [identifier, abort, url, event_source_url, cb]
}

export function unsubscribe(identifier, _abort, _url, event_source_url, cb) {
  stop(event_source_url, cb, {keep_stream: false})
}

export async function send(message, identifier, abort, url, _event_source_url, _cb) {
  const response = await fetch(`${url}?poll-queue=32`, {
    signal: abort.signal,
    credentials: 'include',
    method: 'POST',
    headers: { 'content-type': 'application/json', },
    body: JSON.stringify([
      {identifier, send: message}
    ])
  })

  return response.json()
}

export let channelMixin = {
  get isEventSource() {
    return true
  },

  receivedEvent(message, data) {
    return this.received(message)
  },

  closeEvent(){
    return unsubscribe(...this.subscription)
  },

  perform(action, params) {
    return send({ action, ...params }, ...this.subscription)
  },

  close(){
    return this.closeEvent()
  },
}

let disconnectNotification
function getDisconnectNotification() {
  if (!disconnectNotification) {
    disconnectNotification = document.createElement('div')
    disconnectNotification.classList.add('c-current-view-changed')
    disconnectNotification.textContent = I18n.t('front.long_poll.disconnected')
    disconnectNotification.addEventListener('click', e => window.turboReload())
  }
  return disconnectNotification
}

function inviteToReload(){
  if (automatic_reload) {
    window.turboReload()
  } else {
    document.body.append(getDisconnectNotification())
  }
}

// Remove disconnect notification on pare load, we assume all elements are fresh
document.addEventListener('turbo:render', (event) => {
  getDisconnectNotification().remove()
})

export function createChannel(event_source_url, channel, mixin){
  let chan = Object.create({
    ...channelMixin,
    ...mixin
  })
  chan.subscription = subscribe(event_source_url, channel, (data, chan_id, sess_id) => {
    if (data.type == 'received') {
      chan.receivedEvent(data.message, data)
    }
  })
  return chan
}
