import ApplicationController from '../application_controller'

export default class ScrollIntoViewController extends ApplicationController {

  connect(){
    this.element.scrollIntoView()
  }

}

