import ApplicationController from '../application_controller'
import { init } from 'ui-bibz-js'

export default class UiSwitchFieldFixController extends ApplicationController {

  connect(){
    for (let input of this.element.querySelectorAll('input.ui_switch_field')) {
      this.addAction('input', 'refreshFieldOnEvent', input)
      this.refreshField(input)
    }
  }

  refreshFieldOnEvent(e) {
    return this.refreshField(e.target)
  }

  refreshField(checkbox) {
    let hiddenField = this.findHiddenField(checkbox)
    if (checkbox.checked || checkbox.disabled) {
      hiddenField.name = ''
    } else {
      hiddenField.name = checkbox.name
    }
  }

  findHiddenField(checkbox) {
    let hidden = checkbox.previousElementSibling
    return hidden
  }

}
