import { Controller } from '@hotwired/stimulus'

let expectedControllers = null
let debugControllerInit = false

export default class extends Controller {

  initialize(){
    if (debugControllerInit) console.log('Controller ' + this.identifier + ' initialized! %o', this)
  }

  connect(){
    this._addLcasController()
    if (debugControllerInit) console.log('Controller ' + this.identifier + ' connected! %o', this)
  }

  diconnect(){
    if (debugControllerInit) console.log('Controller ' + this.identifier + ' disconnected! %o', this)
  }

  getInterfaceName(){
    return document.body.dataset.interface || document.querySelector('body > [data-interface-name]').getAttribute('data-interface-name')
  }

  getController(identifier, element){
    return (this._findLcasController(identifier, element) || {}).controller
  }

  openMakeForm(url){
    if (url.match(/\?/)) {
      url = `${url}&popup=true`
    } else {
      url = `${url}?popup=true`
    }
    const height = window.innerHeight * 0.75
    const width = window.innerWidth * 0.75
    document.dispatchEvent(new CustomEvent('form-open'))
    const win = window.open(url, '_blank', [
      `height=${height}`,
      `width=${width}`,
      'toolbar=no,location=no,directories=no,personalbar=no',
      'resizable=yes,scrollbars=no,dependent=no,modal=no,dialog=no',
    ].join(','))
    win.resizeTo(width, height)
  }

  addTurboBlacklist(url){
    if (!window.turboBlacklist) window.turboBlacklist = []
    if (window.lcas_debug?.includes('turbo-blacklist')) console.log('Add %o to turbo blacklist', url)
    window.turboBlacklist.push(url)
  }

  getControllerLength(){
    return document.querySelectorAll('[data-controller]').length
  }

  addTurboBlacklistedLinks(){
    // Disabled, this might cause interaction issues
    // document.querySelectorAll('[data-turbo="false"], [data-turbo="false"] a').forEach(x => this.addTurboBlacklist(x.getAttribute('href')))
  }

  _addLcasController(){
    let c = this._findLcasController(this.identifier, this.element)

    if (!c) {
      if (this.application.myControllers) this._cleanupLcasControllers()
      else this.application.myControllers = []
      this.application.myControllers = [
        ...this.application.myControllers,
        ...Array.from(document.querySelectorAll('[data-controller]'))
          .reduce((res, element) => ([
            ...res,
            ...element.dataset.controller
              .split(' ')
              .filter(x => !!x.length)
              .map(identifier => ({element, identifier}))
          ]), [])
          .filter(c2 => !this.application.myControllers
            .find(c1 => c1.identifier == c2.identifier && c1.element == c2.element))
      ]
      for (const c of this.application.myControllers) {
        if (debugControllerInit) console.log('Controller %s %s: %o',
          c.identifier, c.controller ? 'already initialized' : 'pending initialisation', c)
      }

      c = this._findLcasController(this.identifier, this.element)
    }

    c.controller = this
  }

  _cleanupLcasControllers(){
    this.application.myControllers = this.application.myControllers
      .filter(c => c.element.isConnected)
  }

  _findLcasController(identifier, element) {
    return (this.application.myControllers || []).find(x => x.identifier == identifier && (element === undefined || x.element == element))
  }

  _pendingControllers(){
    return this.application.myControllers.filter(x => (!x.controller))
  }

  _removeSingleItemFromArray(array, item){
    let res = []
    let removed = false
    for (let n of array) {
      if (n == item && !removed) {
        removed = true
      } else {
        res.push(n)
      }
    }
    return res
  }

  getStorageName(name){
    let iface = this.getController('interface')
    if (iface) {
      return ['interface', iface.version, document.body.dataset.interface, name, iface.rootId].join('-')
    } else {
      return ['interface', document.body.dataset.version, document.body.dataset.interface, name, document.body.dataset.rootId].join('-')
    }
  }

  getGlobalStorageName(name){
    return ['global', name].join('-')
  }

  updateTheme(e){
    e.target.closest('form').submit()
  }

  _addInHTMLList(list, element){
    return (list || '').split(' ').filter(x => x != element).concat(element).join(' ').trim()
  }

  addAction(eventName, callback, elements){
    elements = elements || this.element
    if (! (elements instanceof Array) && ! (elements instanceof NodeList)) {
      elements = [elements]
    }
    for (let element of elements) {
      if (!element) continue
      if (!callback.match(/^(.*)#(.*)$/)) {
        callback = `${this.identifier}#${callback}`
      }
      element.dataset.action = this._addInHTMLList(element.dataset.action,`${eventName}->${callback}`)
    }
  }

  isInputKeydown(e){
    return e.target.tagName == 'INPUT' && ['text', 'search', 'number'].includes(e.target.type)
  }
}
