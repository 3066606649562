import ApplicationController from '../application_controller'

export default class DetailsController extends ApplicationController {

  connect(){
    super.connect()
    this.names = this.data.get('names').split(' ')
    for (let name of this.names) {
      this.addAction(`${name}-selected@document`, 'onItemSelected')
    }
    this.url_single = this.data.get('url-single')
    this.debug = !!this.data.get('debug')
    this.element.setAttribute('data-details-level', '0')
    if (this.element.querySelector('.panel-body').innerHTML.trim() == '') {
      this.hideDetails()
    }
  }

  onItemSelected(e){
    this.loadDetails(e.detail.selection)
  }

  loadDetails(items){
    var url = null
    let type = items.length > 0 ? items[0].dataset.type.toLowerCase() : null

    const type_excl = ['category', 'virtual', 'root']
    const id_excl = ['db_flows', 'db_modules', 'indicators_sets', 'indicators', 'unit_groups', 'tags']

    if (type && type_excl.includes(type)) {return}
    if (id_excl.includes(items[0].value)) {return}

    if (items.length == 1 && this.data.has(`url-${type}`)) {
      url = this.data.get(`url-${type}`).replace(':ref_id', items[0].value)
    } else if (items.length == 1 && this.url_single) {
      url = this.url_single.replace(':ref_id', items[0].value)
    }
    if (url) {
      if (this.debug) console.log('Loading details for %o with %s', Array.from(items, i => i.value), url)
      $.get({ url: url, dataType: 'html'})
        .done((html) => {
          if (this.debug) console.log('Loaded details for %o', Array.from(items, i => i.value))
          this.element.querySelector('.panel-body').innerHTML = html
          if (this.element.querySelector('.details-hide')) {
            this.hideDetails()
          } else {
            this.showDetails()
          }
        })
    } else {
      console.warn('No URL to load details for %o', Array.from(items, i => i.value))
    }
  }

  lessInfo(){
    this.element.setAttribute('data-details-level', '0')
  }

  moreInfo(){
    this.element.setAttribute('data-details-level', '1')
  }

  copyRef(e){
    e.preventDefault()
    let input = null
    let target = e.target
    while (!input) {
      target = target.parentElement
      input = target.querySelector('input')
    }

    input.focus()
    input.select()

    try {
      let successful = document.execCommand('copy')
      if (successful) return
    } catch (err) {
      // do nothing
    }

    // Failed to copy, remove button
    e.target.remove()
  }

  hideDetails(){
    this.element.classList.add('hidden')
    this.element.parentElement.classList.add('details-hidden')
  }

  showDetails(){
    this.element.classList.remove('hidden')
    this.element.parentElement.classList.remove('details-hidden')
  }

}
