import subscribe_all from './helper/subscribe_all'

let mixin = {

  received(data){
    console.log('CurrentViewChannel received %o', data)
    this.controller.currentViewChanged(data)
  },

}

export default function(controller, params) {
  const channel = 'CurrentViewChannel'
  return subscribe_all({channel, ...params}, mixin, (subscription) => {
    subscription.controller = controller
  })
}
