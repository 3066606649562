import ApplicationController from './../application_controller'

// Make Completed
// ==============
//
// This controller executes on the completed page after a form is submitted in
// popup mode. Its role is to close the popup.

export default class extends ApplicationController {

  connect(){
    super.connect()
    this.completed()
  }

  completed(){
    if (window.opener) {
      window.close()
    } else {
      // Closed by parent
    }
  }

}
