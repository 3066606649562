import AdminController from '../admin_controller'

export default class AdminLayoutController extends AdminController {

  static values = {
    treeMinWidth: Number,
    detailsMinWidth: Number
  }

  connect(){
    super.connect()

    this.tree_min_width = this.data.get('tree-min-width') || 50
    this.details_min_width = this.data.get('details-min-width') || 50

    this.tree = this.element.querySelector('.c-admin-layout__tree')
    this.details = this.element.querySelector('.c-admin-layout__details')
    this.tree_handle = this.element.querySelector('.c-admin-layout__tree-handle')
    this.details_handle = this.element.querySelector('.c-admin-layout__details-handle')

    this.addAction('mouseover', 'showHideHandle', this.tree_handle)
    this.addAction('mouseout', 'showHideHandle', this.tree_handle)
    this.addAction('mouseover', 'showHideHandle', this.details_handle)
    this.addAction('mouseout', 'showHideHandle', this.details_handle)

    this.initDetailsWidth()
    this.resizeTree()
    this.resizeDetails()
  }

  //
  // Tree
  //

  resizeTree(){
    $(this.tree).resizable({
      minWidth: this.tree_min_width,
      handles: {'e': this.tree_handle},
      stop: (u, ui) => {
        sessionStorage.setItem(this.getStorageName('tree-width'), ui.size.width)
      }
    })
  }

  //
  // Details
  //

  initDetailsWidth(){
    let width = sessionStorage.getItem(this.getStorageName('details-width'))
    if (width != null) this.details.style.width = `${ width }px`
  }

  resizeDetails(){
    $(this.details).resizable({
      minWidth: this.details_min_width,
      handles: 'w',
      resize: (ev, ui) => {
        ui.element[0].style.left = null
      },
      stop: (u, ui) => {
        sessionStorage.setItem(this.getStorageName('details-width'), ui.size.width)
      }
    })
  }

  //
  // Handles
  //

  showHideHandle(e){
    let matches = Array.from(e.target.classList, cls => cls.match(/^c-admin-layout__(.*)-handle$/)).filter(x => x)
    if (!matches.length) return

    let component = matches[0][1]
    if (e.type == 'mouseover') {
      this.element.classList.add(`c-admin-layout--${component}-resizing`)
    } else if (e.type == 'mouseout') {
      this.element.classList.remove(`c-admin-layout--${component}-resizing`)
    }
  }

}