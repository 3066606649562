import ApplicationController from './../application_controller'

export default class extends ApplicationController {

  static targets = ['inputSearch']

  updateSearch(e){
    let ul = this.element.querySelector('.results')
    let url = this.url
    let value = e.target.value

    $.get({ url: `${ url }?query=${ value }`}).done(function(htmlRecords){
      ul.innerHTML = htmlRecords
    })
  }

  get url(){
    return this.data.get('url')
  }


}
