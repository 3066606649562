import ApplicationController from '../application_controller'
import Loader from '../../src/lib/loader.js'

// Infinite Scroll Controller
// ==========================
//
// This controller manages infinite scrolling. Applied to an element, it expand
// automatically placeholder elements when they reach view.
//
// Unless you specify data-infinite-scroll-root-selector-value, the element for
// which you declare the controller will be compared for intersection with the
// trigger. You must ensure that the root element will have a bounded size and
// the overflow is inside. Else the infinite scroll will never stop.
//
// Attributes for the controller element:
//
// data-infinite-scroll-root-selector-value=""
//
//     If specified, compares the bounding box with the element matching this
//     CSS selector.
//
// Events
//
// c-infinite-scroll-loading
//
//     detail:
//         url: URL being loaded
//
// c-infinite-scroll-loaded
//
//     detail:
//         url: URL loaded
//
// Elements under the controller:
//
// .c-infinite-scroll__trigger
//
//     This signals a placeholder element that will be expanded to a list of
//     items when it appears in the view.
//
//     data-infinite-scroll-url="" contains the URL that is fetched with a GET
//     request to replace the placeholder element. Ideally, the resulting
//     fragment will contain another trigger if there are still elements to
//     load.
//
//     data-infinite-scroll-selector="" contains the selector to find the
//     fragment to replace in the above request. Optional.
//
//     data-infinite-scroll-loading="true" is set when the replacement request
//     is in progress to display a spinner or a visual indicator that loading is
//     in progress.
//

const debug = false

export default class InfiniteScrollController extends ApplicationController {

  static values = { rootSelector: String }

  connect(){
    super.connect()
    this.debug = debug

    if (this.hasRootSelectorValue) {
      this.rootElement = this.element.querySelector(this.rootSelectorValue)
    } else {
      this.rootElement = this.element
    }

    if (this.debug) console.log('infinite-scroll connect %o to %o', this, this.rootElement)

    this.mutation = new MutationObserver((list, o) => this.mutate(list, o))
    this.mutation.observe(this.rootElement, {subtree: true, childList: true})

    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    this.intersectObs = new IntersectionObserver((e, o) => this.intersect(e, o), {
      root: this.rootElement
    })

    this.registerTriggers(this.rootElement)
  }

  registerTriggers(root) {
    if (this.debug) console.log('infinite-scroll register triggers from %o', root)
    for (let trigger of root.querySelectorAll('.c-infinite-scroll__trigger')) {
      trigger.loader = new Loader(trigger)
      trigger.append(document.createTextNode(I18n.t('front.controllers.infinite_scroll.more_items')))
      trigger.loader.disable()
      if (this.debug) console.log('infinite-scroll register trigger %o for %o', trigger, trigger.dataset.infiniteScrollUrl)
      this.intersectObs.observe(trigger)
    }
  }

  mutate(mutationsList, observer) {
    if (this.debug) console.log('infinite-scroll mutate %o', mutationsList)
    for (const mutation of mutationsList) {
      this.registerTriggers(mutation.target)
    }
  }

  intersect(entries, observer) {
    console.log('infinite-scroll intersect %o', entries)
    // entries: list of https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry
    // observer: https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver
    for (let entry of entries) {
      if (!entry.isIntersecting) continue
      let trigger = entry.target
      trigger.loader.enable()
      let href = trigger.dataset.infiniteScrollUrl
      console.log('infinite-scroll load %s', href)
      let selector = trigger.dataset.infiniteScrollSelector
      trigger.dataset.infiniteScrollLoading = true
      if (this.debug) console.log('infinite-scroll fetch %s', href)
      this.element.dispatchEvent(new CustomEvent('c-infinite-scroll-loading', {detail: {url: href}}))
      fetch(href)
        .then((response) => (response.text()))
        .then((text) => {
          let frag = document.createRange().createContextualFragment(text)
          if (selector) frag = frag.querySelector(selector)
          if (this.debug) console.log('infinite-scroll replace %o with %o', trigger, frag)
          //this.registerTriggers(frag)
          trigger.parentElement.replaceChild(frag, trigger)
          this.element.dispatchEvent(new CustomEvent('c-infinite-scroll-loaded', {detail: {url: href}}))
        })
    }
  }

}
