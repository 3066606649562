// import ApplicationController from "../application_controller"
import DetailsController from '../utils/details_controller'

export default class AdminDetailsController extends DetailsController {

  connect(){
    super.connect()
    this.detailsResize()
  }

  onItemSelected(e){
    this.loadDetails(e.detail.selection)
  }

  loadDetails(items){
    let details_body = document.querySelector('.details-panel-body')
    let type = items[0].dataset.type
    let ids = Array.from(items, i => `ids[]=${i.value.split('+').slice(-1)}`).join('&')
    $.get({ url: `/admin/${_.pluralize(type)}/details/?${ids}&partial=true`})
      .done((html) => {
        details_body.innerHTML = html
        if (details_body.querySelector('.details-item.details-show')) {
          this.showDetails()
        } else {
          this.hideDetails()
        }
      })
  }

  detailsResize(){
    $('.details').resizable({
      handles: { w: '.details-handler' },
      minWidth: 20
    })
  }

  hideDetails(){
    document.querySelector('.details-panel-body').closest('.details').classList.add('hidden')
    document.querySelector('.details-panel-body').closest('.details').parentElement.classList.add('details-hidden')
  }

  showDetails(){
    document.querySelector('.details-panel-body').closest('.details').classList.remove('hidden')
    document.querySelector('.details-panel-body').closest('.details').parentElement.classList.remove('details-hidden')
  }

}
