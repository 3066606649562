import BaseActions from '../base_actions.js'

export default class DesignLibraryActions extends BaseActions {

  addDbItemFromUrl({parent_ref_id = null, db_item_id, type_class}){
    if (!parent_ref_id) {
      const href = window.location.href
      if (!parent_ref_id && href.split('/nodes/').length > 1){
        parent_ref_id = href.split('/nodes/')[1].split('?')[0]
      } else if (!parent_ref_id) {
        parent_ref_id = href.split('/root/')[1].split('?')[0]
      }
    }

    let params = {
      root_ref_id: document.body.dataset.rootId,
      db_item_id,
      parent_ref_id,
    }

    let url
    if (type_class == 'Db::Module') {
      url = Routes.new_make_cs_module_path(params)
    } else if (type_class == 'Db::Flow') {
      url = Routes.new_make_cs_flow_path(params)
    } else if (type_class == 'Db::System') {
      url = Routes.new_make_cs_system_path(params)
    } else {
      throw 'Unsupported Db::Item of type #{type_class}'
    }

    this.openForm(url)
  }

}
