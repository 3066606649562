import AnalysisController from '../analysis_controller'
import { draw } from 'patternomaly'
import { fill, remove, startCase, range } from 'lodash'

export default class extends AnalysisController {

  static values = {
    slug:       String,
    order:      {type: Number, default: 1},
    rankColumn: Number,
    direction:  String,
    scientific: Boolean,
    count:      {type: Number, default: 1},
    total:      Number
  }

  initialize(){
    this.generateCharts()
  }

  fixTableGap() {
    $('.DTFC_LeftBodyLiner').attr('style', function(_, s) { return (s || '') + `top: ${parseInt($('.DTFC_LeftBodyLiner').css('top')) + 1}px !important;` })
    $('.DTFC_LeftFootWrapper').attr('style', function(_, s) { return (s || '') + `top: ${parseInt($('.DTFC_LeftFootWrapper').css('top')) + 1}px !important;` })
  }

  generateCharts(){
    console.log('[analysis-overview] generateCharts')
    const colorPicker = document.getElementById('chart_color')
    const self = this
    document.querySelectorAll('.chart-bibz').forEach(function(canvasEl){
      let type = canvasEl.getAttribute('data-cb-type')
      const customOptions = {
        legend: {
          position: 'bottom',
          onHover: function(_event, item) {
            const datasets = type === 'pie' || type === 'radar' ?
              window.analysisChart.data.datasets :
              window.analysisChart.data.datasets.filter(_ => _.label !== item.text)
            datasets.forEach(dataset => {
              if (type === 'radar') {
                if (dataset.label !== item.text) {
                  dataset.backgroundColor = dataset.backgroundColor.replace('0.3', '0.05')
                }
              } else {
                dataset.backgroundColor.forEach((color, index, colors) => {
                  colors[index] = index === item.index || color.length === 9 ? color : color + '4D'
                })
              }
            })
            window.analysisChart.update()
          },
          onLeave: function(_event, item) {
            const datasets = type === 'pie' || type === 'radar' ?
              window.analysisChart.data.datasets :
              window.analysisChart.data.datasets.filter(_ => _.label !== item.text)
            datasets.forEach(dataset => {
              if (type === 'radar') {
                if (dataset.label !== item.text) {
                  dataset.backgroundColor = dataset.backgroundColor.replace('0.05', '0.3')
                }
              } else {
                dataset.backgroundColor.forEach((color, index, colors) => {
                  colors[index] = color.length === 9 ? color.slice(0, -2) : color
                })
              }
            })
            window.analysisChart.update()
          },
          onClick: function(event, item) {
            if (event.altKey || event.ctrlKey || event.shiftKey) {
              const dataset = window.analysisChart.data.datasets.find(_ => _.label === item.text)
              if (dataset != null) {
                colorPicker.value = dataset.backgroundColor[0]
                colorPicker.addEventListener('change', function () {
                  if (Array.isArray(dataset.backgroundColor)) {
                    dataset.backgroundColor = fill(Array(dataset.backgroundColor.length), this.value)
                  } else {
                    const { r, g, b } = self.hexToRgb(this.value)
                    dataset.backgroundColor = `rgba(${r}, ${g}, ${b}, 0.3)`
                    dataset.borderColor = `rgba(${r}, ${g}, ${b}, 0.3)`
                  }
                  window.analysisChart.update()
                }, { once: true })
              } else {
                const index = window.analysisChart.data.labels.indexOf(item.text)
                colorPicker.value = window.analysisChart.data.datasets[0].backgroundColor[index]
                colorPicker.addEventListener('change', function () {
                  window.analysisChart.data.datasets[0].backgroundColor[index] = this.value
                  window.analysisChart.update()
                }, { once: true })
              }
              colorPicker.style.top = `${event.offsetY}px`
              colorPicker.style.left = `${event.offsetX}px`
              colorPicker.click()
            } else {
              if (type === 'pie') {
                const index = window.analysisChart.data.labels.indexOf(item.text)
                const lastIndex = window.analysisChart.data.labels.length - 1
                const value = window.analysisChart.data.datasets[0].data[index]
                let meta = window.analysisChart.getDatasetMeta(0)
                meta.data[index].hidden = !meta.data[index].hidden
                if (index !== lastIndex) {
                  if (meta.data[index].hidden) {
                    window.analysisChart.data.datasets[0].data[lastIndex] += value
                  }
                  else {
                    window.analysisChart.data.datasets[0].data[lastIndex] -= value
                  }
                  window.analysisChart.data.labels[lastIndex] = `${window.analysisChart.data.labels[lastIndex].split(' ')[0]} (${window.analysisChart.data.datasets[0].data[lastIndex].toFixed(2)}%)`
                }
                window.analysisChart.update()
              } else {
                Chart.defaults.global.legend.onClick.call(this, event, item)
              }
            }
          }
        }
      }
      canvasEl.addEventListener('dblclick', function(event) {
        const activePoint = window.analysisChart.getElementAtEvent(event)[0]
        if (activePoint != null) {
          const { datasetLabel } = activePoint._model
          const td = $(`#indicators-table td[data-label="${datasetLabel}"][data-action]`)
          if (td.length) {
            document.getElementById('chart-loader').classList.remove('d-none')
            td.trigger('click')
          }
        }
      })
      let options = Object.assign(JSON.parse(canvasEl.getAttribute('data-cb-options')), customOptions)
      let data = JSON.parse(canvasEl.getAttribute('data-cb-data'))
      let ctx = canvasEl.getContext('2d')

      if (data.defaultBackgroundColor){

        // Add Pattern if the array has 2 dimensions
        if (Array.isArray(data.defaultBackgroundColor[0])){
          //data.backgroundColor = data.backgroundColor.map(x => draw(x.shift(), x.shift()))
          data.defaultBackgroundColor = data.defaultBackgroundColor.map(function(x, index){
            let shape = x.shift()
            let backgroundColor = x.shift()
            let patternColor = x.shift()
            let patternSize = x.shift()
            //if(data.defaultBackgroundColorType == "pattern" && index >= 6){
            //if(data.defaultBackgroundColorType == "pattern"){
            if (data.defaultBackgroundColorType == 'pattern' && index >= data.colorsNumber){
              return draw(shape, backgroundColor, patternColor, patternSize)
            } else {
              return backgroundColor
            }
          })
        }

        // Add default backgroundColor if the dataset doesn't contain
        data.datasets.map(function(dataset,index){
          if (!data.backgroundColor && (type == 'horizontalBar' || type == 'bar')) { dataset.backgroundColor = Array(dataset.data.length).fill(data.defaultBackgroundColor[index]) }
          if (!data.backgroundColor && (type == 'pie')) { dataset.backgroundColor = data.defaultBackgroundColor }
          if (!data.backgroundColor && (type == 'radar')) {
            const { r, g, b } = self.hexToRgb(data.defaultBackgroundColor[index])
            dataset.backgroundColor = `rgba(${r}, ${g}, ${b}, 0.3)`
            dataset.borderColor = `rgba(${r}, ${g}, ${b}, 0.3)`
          }
        })
      }

      // Insert original Data
      //window.analysisChartData = _.cloneDeep(data)
      window.analysisChartData = $.extend(true, {}, data)

      window.analysisChart = new Chart(ctx, { type: type, data: data, options: options })

      // This applies in comparison, see commit 194c3f7ac29f06ae7d21c652702ce31177a0b1f6
      document.getElementById('min')?.addEventListener('change', (e) => {
        window.analysisChart.options.scale.ticks.min = parseInt(e.target.value, 10)
        window.analysisChart.update()
      })
      document.getElementById('step_size')?.addEventListener('change', (e) => {
        window.analysisChart.options.scale.ticks.stepSize = parseInt(e.target.value, 10)
        window.analysisChart.update()
      })
    })
  }

  async expand(e) {
    const $target = $(e.target)
    const targetRowId = $target.closest('tr').data().rowId
    const $row = $(`[data-row-id=${targetRowId}]:first`)
    const rorder_prefix = $row.get(0).dataset.rorder.replace(']', ',')
    const { expanded, loaded, rowId, level, rorder } = $row.data()

    if ($target.closest('tr').data().expanding) { // prevent dblclick
      return false
    } else {
      $target
        .closest('tr')
        .data('expanding', true)
    }

    if (expanded) {
      $row
        .data('expanded', false)
        .find('td')
        .find('.glyph.fa-caret-down')
        .removeClass('fa-caret-down')
        .addClass('fa-caret-right')
      this.addToCharts($row.get(0), 0)
      for (let element of $row.nextAll()) {
        if (element.dataset.rorder.startsWith(rorder_prefix)) {
          this.removeFromCharts(element.dataset.rowId)
          $(element)
            .data('expanded', false)
            .hide()
            .find('.glyph.fa-caret-down')
            .removeClass('fa-caret-down')
            .addClass('fa-caret-right')
        }
      }
    } else {
      if (!loaded) {
        $target
          .closest('tr')
          .find('.icon, .spinner')
          .toggleClass('d-none')
        const req = await fetch(`${window.location.pathname}/children?child_id=${rowId}&level=${level+1}`)
        const partial = await req.text()
        $(partial).filter('tr').each((index, element) => {
          window.analysisTable.row.add(element).draw(false)
          this.addToCharts(element, index)
        })
        $row.data('loaded', true)
        $target
          .closest('tr')
          .find('.icon, .spinner')
          .toggleClass('d-none')
      }
      this.removeFromCharts($target.closest('tr').data().rowId)
      $row
        .data('expanded', true)
        .find('td')
        .find('.glyph.fa-caret-right')
        .removeClass('fa-caret-right')
        .addClass('fa-caret-down')
      let index = 0
      for (let element of $row.nextAll()) {
        if (loaded && element.dataset.rorder.startsWith(rorder_prefix) && $(element).data().rorder.length === rorder.length + 1) {
          this.addToCharts(element, index)
          index++
          $(element)
            .data('expanded', true)
            .show()
        }
      }
    }
    document.getElementById('chart-loader').classList.add('d-none')
    window.analysisTable.draw()
  }

  getChartData(element, index) {
    console.log('[analysis-overview] getChartData')
    const { rowId } = element.dataset
    const { order, label } = element.querySelector('td').dataset
    const values = [...element.querySelectorAll('td.percent')].map((element, index) => {
      return { label: window.analysisChart.data.labels[index], id: element.classList[0], y: element.textContent.replace(',', '.') }
    })
    const backgroundColor = fill(Array(values.length), window.analysisChart.data.defaultBackgroundColor[window.analysisChart.data.datasets.length + index + 1])
    const child = { label, data: [], backgroundColor, order: parseInt(order.padEnd(100, '0')), ref_id: rowId }
    window.analysisChart.data.labels.forEach(x => {
      const value = values.find(_ => _.label === x)
      child.data.push({ x, y: value.y, id: value.id })
    })
    return child
  }

  removeFromCharts(rowId) {
    console.log('[analysis-overview] removeFromCharts')
    remove(window.analysisChart.data.datasets, _ => _.ref_id === rowId)
    window.analysisChartData = window.analysisChart.data
    window.analysisChart.update()
  }

  addToCharts(element, index) {
    console.log('[analysis-overview] addToCharts')
    const data = this.getChartData(element, index)
    window.analysisChart.data.datasets.push(data)
    window.analysisChartData = window.analysisChart.data
    window.analysisChart.update()
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null
  }

  // Render a column with ellipsed strings as values, if the string is big or the table size is small.
  // Currently applies only on Analysis / Comparison tables.
  renderWithEllipsis ( data, type, row ) {
    const tableSize = $('#all-indicators-values').width()
    if (!tableSize) return data

    const maxChar = parseInt(tableSize * 0.15)
    return type === 'display' && data.length > maxChar ? data.substr( 0, maxChar ) +'…' : data
  }
}
