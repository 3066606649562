import ApplicationController from '../application_controller'

export default class AdminSearchController extends ApplicationController {

  connect(){
    super.connect()
    var filters = document.getElementById('filters')
    var tree_position = filters.dataset.position
    var role = filters.dataset.role
    var instance = filters.dataset.instance
    var company = filters.dataset.company
    var department = filters.dataset.department
    var licence_status = filters.dataset.license

    if (licence_status != null){
      $('#licence_statut').val(licence_status)
    }
    if (instance != null){
      $('#instance').val(instance)
    }
    if (company != null){
      $('#company').val(company)
    }
    if (department != null){
      $('#company').val(department)
    }
    if (role != null){
      $('#role').val(role)
    }
    if (tree_position != null){
      var activ_element = document.querySelector("label[data-id='"+tree_position+"']")
      this.changeActivElement(activ_element)
    }
  }

  changeActivElement(newActivElement){
    this.removeActivElement
    newActivElement.classList.add('active')
  }

  removeActivElement(){
    let active = document.querySelector('li label.active')
    if (active){
      active.classList.remove('active')
    }
  }
}
