// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import { createConsumer } from '@rails/actioncable'


export default (function(){
  let consumer = createConsumer() //"ws://example.org/cable")
  //setInterval(() => {console.log("isOpen", consumer.connection.isOpen())}, 1000)
  return consumer
})()
