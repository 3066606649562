import ApplicationController from '../application_controller'

let debug = window.lcas_debug?.includes('session-frame') // ?debug[session_frame]=1

export default class SessionFrameController extends ApplicationController {

  connect(){
    super.connect()

    // TODO: replace this when PR merged
    // <https://github.com/hotwired/turbo/pull/59> and Turbo updated
    this.observer = new MutationObserver(mutationsList => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'src') {
          this.onFrameChange()
        }
      }
    })
    this.observer.observe(this.element, { attributes: true })

    let dataset = document.body.dataset
    this.sessionKey = `session-frame+${dataset.interface}+${dataset.rootId}+${dataset.version}+${this.element.id}+src`

    this.onInit()
  }

  disconnect(){
    try {
      this.observer.disconnect()
    } finally {
      super.disconnect()
    }
  }

  onInit(){
    let url = sessionStorage.getItem(this.sessionKey)
    if (debug) console.log('session-frame restore %s=%s', this.sessionKey, url)

    let oldUrl = this.element.src
    if (url && url != oldUrl) {
      //this.element.setAttribute('src', url)
      this.element.src = url
      // FIXME: ugly fix. On pae load, the original URL is requested and if the
      // original URL request comes later, we have a problem and the new URL is
      // overridden
      setTimeout(() => {if (this.element.src == oldUrl) this.element.src = url}, 100)
      setTimeout(() => {if (this.element.src == oldUrl) this.element.src = url}, 500)
    }
  }

  onFrameChange(){
    if (debug) console.log('session-frame save %s=%s', this.sessionKey, this.element.getAttribute('src'))
    sessionStorage.setItem(this.sessionKey, this.element.getAttribute('src'))
  }

}

