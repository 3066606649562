// IMPORTANT: use the same bootstrap import path throught the application
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'

const debug = window.lcas_debug?.includes('modal') // ?debug[modal]=1

export default class {

  constructor(opts = {}){
    const div = document.createElement('div')
    div.innerHTML = `
    <div class="modal fade show" ${opts.static === false ? '' : 'data-bs-backdrop="static"'} data-bs-keyboard="false" tabindex="-1" style="display: block">
      <div class="modal-dialog modal-dialog-centered ${opts.modal_dialog_classes || ''}">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-cancel"></button>
            <button type="button" class="btn btn-primary btn-ok"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
    <div class="d-flex justify-content-center">
      <div class="spinner-border" style="width: 5rem; height: 5rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    `
    this.modalElement = div.firstElementChild
    this.backdropElement = this.modalElement.nextElementSibling
    this.loaderElement = this.backdropElement.nextElementSibling

    this.modal = new bootstrap.Modal(this.modalElement, {})
    this.titleElement = this.modalElement.querySelector('.modal-title')
    this.bodyElement = this.modalElement.querySelector('.modal-body')
    this.btnClose = this.modalElement.querySelector('.btn-close')
    this.btnOk = this.modalElement.querySelector('.btn-ok')
    this.btnCancel = this.modalElement.querySelector('.btn-cancel')
    this.opts = opts

    if (opts.static !== false) this.btnClose.remove()
    if (opts.text) this.bodyElement.textContent = opts.text
    if (opts.textHTML) this.bodyElement.innerHTML = opts.textHTML
    if (!opts.confirm) this.btnCancel.remove()

    if (opts.loader) {
      this.modalElement.querySelector('.modal-footer').remove()
      this.bodyElement.append(this.loaderElement)
    }

    if (opts.title) this.titleElement.textContent = opts.title
    else this.modalElement.querySelector('.modal-header').remove()

    if (opts.okText) this.btnOk.textContent = opts.okText
    else this.btnOk.textContent = I18n.t('ok')

    if (opts.cancelText) this.btnCancel.textContent = opts.cancelText
    else this.btnCancel.textContent = I18n.t('cancel')

    this.onClick = this.onClick.bind(this)
    this.onBackdropClick = this.onBackdropClick.bind(this)
    this.modalElement.addEventListener('click', this.onClick)
    //this.backdropElement.addEventListener('click', this.onBackdropClick)
    this.acceptCbs = []
    if (opts.enable) this.enable()
  }

  async disable(){
    if (this.lastTransition) await this.lastTransition
    this.lastTransition = new Promise((accept, reject) => {
      this.modalElement.addEventListener('hidden.bs.modal', accept, {once: true})
    })
    this.modal.hide()
    //this.modalElement.remove()
    //this.backdropElement.remove()
    if (debug) console.log('modal.disable()', this, this.modal, this.modalElement, this.backdropElement, this.modalElement.parentElement, this.backdropElement.parentElement)
    return this.lastTransition
  }

  async enable(){
    if (this.lastTransition) await this.lastTransition
    document.body.append(this.modalElement)
    this.lastTransition = new Promise((accept, reject) => {
      this.modalElement.addEventListener('shown.bs.modal', accept, {once: true})
    })
    this.modal.show()
    this.modalElement.focus()
    if (debug) console.log('modal.enable()', this, this.modal, this.modalElement, this.backdropElement)
    //if (this.opts.backdrop !== false) document.body.append(this.backdropElement)
    this.interactPromise = new Promise((accept, reject) => {
      this.acceptCbs.push(accept)
    })
    return this.lastTransition
  }

  interacted(){
    return this.interactPromise
  }

  onClick(e) {
    if (e.target == this.btnCancel) {
      this._accept(false)
      this.disable()
    } else if (e.target == this.btnOk) {
      this._accept(true)
      this.disable()
    }
  }

  onBackdropClick(e) {
    e.preventDefault()
    if (this.opts.static === false) this.disable()
  }

  _accept(value) {
    for (let cb of this.acceptCbs) cb(value)
    this.acceptCbs = []
  }

}
