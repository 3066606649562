import { subscribe_one } from './helper/subscribe_all'

export let debug = window.lcas_debug?.includes('actions_channel')

if (window.lcas_user_id) {
  subscribe_one({channel: 'ActionsChannel'}, {
    connected() {
      if (debug) console.log('ActionsChannel connected !')
    },

    disconnected() {
      if (debug) console.log('ActionsChannel disconnected !')
    },

    received(data) {
      if (debug) console.log(data)
      if (data.state === 'in_progress') {
        this.updateProgressBar(data)
      } else {
        this.updateView(data)
      }
    },

    updateView(data) {
      let views = $('.tree .view, .overview .view')

      views.each(function() {
        $(this).load($(this).data().url)
      })
    },

    updateProgressBar(data) {
      let inputs = $('.view input[value=' + data.record_id + ']')

      inputs.each(function() {
        var form, item, percentage, progressBar
        form = $(this).next()
        item = $(this).parents('li').first()
        progressBar = form.find('.progress .progress-bar')
        percentage = data.percentage + '%'

        form.find('.progress').removeClass('hidden')
        progressBar.width(percentage)
        if (progressBar.data().label !== false) { progressBar.text(percentage) }
        item.css('background', 'linear-gradient(90deg, rgba(0,0,0,0.3), transparent ' + data.percentage + '%)')
      })
    }
  })
}
