import ApplicationController from './application_controller'
import SelectionMixin from './concerns/selection_mixin'

export default class DesignController extends ApplicationController {

  getRootItem(){
    let interfaceController = this.getController('interface')

    return JSON.parse(interfaceController.data.get('root-item'))
  }

  clickOnFirstInput(){
    // Click on the first input on region
    this.getFirstInput().click()
    // Scroll up to top
    $(this.element.querySelector('.tree-body, .t-body, .library-tree-body')).scrollTop(0)
    // Focus on the region
    this.element.focus()
  }

  getFirstInput(){
    return this.element.querySelector('input[type=radio]')
  }

  _selectItem(item){
    item.click()
    this._scrollbarFollowSelectItem(item)
  }

  _scrollbarFollowSelectItem(item){
    let li = $(item.closest('li'))
    let parent = $(item.closest('.scrollbar'))
    let scrollTop = li.offset().top - (parent.height() + li.height())

    // Scrollbar follow
    if (li.offset().top > parent.height()){
      parent.scrollTop(parent.scrollTop() + li.height())
    }
    if (li.offset().top < parent.offset().top){
      parent.scrollTop(parent.scrollTop() - li.height())
    }
  }

  _getRegion(checkedItem){
    return checkedItem.closest('.region')
  }

  _getRegionId(checkedItem){
    return this._getRegion(checkedItem).getAttribute('id')
  }

  _isGlobalKeys(e){
    return e.shiftKey && e.ctrlKey
  }

  // Protected functions //////////////////////////////////////////////////////

  _getCheckedItem(){
    return document.querySelector('.region input[type=radio]:checked, .region input[type=checkbox]:checked')
  }

}

// To extract shortcuts functions in other file
Object.assign(DesignController.prototype, SelectionMixin)
