// Selection Mixin
// ===============
//
// This selection mixin is to be included in every controller that manages a
// selection. It is included with:
//
//     Object.assign(YourController.prototype, SelectionMixin)
//
// How selection works
// -------------------
//
// Selection works by having invisible radio buttons or checkboxes for each
// selectable item. When clicking on a label, the input is checked and the item
// is selected.
//
// It is possible to define multiple selection realms by using a different name
// for the input. Currently this is not used, but it may be useful in the
// future. The default name is "node".
//
// Hook up widgets to selection mixin
// ----------------------------------
//
// Each controller that needs to manage a selection (that contains selectable
// items) must listen to the click and keydown events that can change the
// selection, and forward these events to the selection mixin.
//
// Example:
//
//     connect(){
//       super.connect()
//       this.addAction('keydown', 'selectKeydown')
//       this.addAction('click', 'selectItem')
//     }
//
// Of course, it is possible to define your own methods to filter the events
// before they are passed to the selection mixin (to handle your own clicks and
// your own shortcuts).
//
// Available methods
// -----------------
//
// ### selectItem(e)
//
// Must be called on click.
//
// ### selectKeydown(e)
//
// Must be called on keydown.
//
// ### getSelecteditems(name = 'node')
//
// Return a collection of selected item. Each selected item is represented by
// its HTML input element.
//
// ### getSelection(name = 'node')
//
// Get the selection object. You should not need to use it except if you need to
// handle click or keydown events with a different selection name.

class Selection {
  constructor(name, rootElement){
    this.lastChecked = null
    this.multiSelection = false
    this.name = name
    this.root = rootElement.ownerDocument
    this.selection = Array.from(this.root.querySelectorAll(`[name='${this.name}']:checked`))
    this.oldSelection = ''

    this.initSelection()
  }

  getSelectedItems(){
    return document.querySelectorAll(`input[name='${this.name}']:checked`)
  }

  initSelection(){
    const items = Array.from(this.getSelectedItems())
    return this._dispatchEvent(document, items, null)
  }

  // On keydown, set multiselection
  selectionKeydown(e){
    if (e.key == 'ArrowDown') this._keyboardSelect(e, 1)
    if (e.key == 'ArrowUp') this._keyboardSelect(e, -1)
    if (e.key == ' ') this._keyboardSelect(e, 0)
  }

  // When click on node label
  selectionClick(e){
    let control = null
    let label = null

    let controlKey = e.ctrlKey && e.type == 'click'

    if (e.target.tagName == 'INPUT') {
      control = e.target
      label = control.labels[0]
    } else {
      label = e.target.closest('label')
      control = label.control
    }

    if (control.name != this.name) return

    let selection = [control]
    let inputs = Array.from(this.root.querySelectorAll(`[name='${this.name}']`))

    // On right click, do not proceed if the current element is already selected
    if (e.button == 2 && control.checked) {
      let detail = { name: this.name, selection: this.selection, originalEvent: e }
      return detail
    }

    // To fix focus bug with input tag
    if (document.activeElement != control) label.focus()

    if (!this.lastChecked) {
      this.lastChecked = control
    }

    // Add multiselect by shift
    if (e.shiftKey) {
      this._changeRadioToCheckbox(inputs)
      const start = inputs.indexOf(control)
      const end = inputs.indexOf(this.lastChecked)

      inputs
        .slice(Math.min( start, end ), Math.max(start, end) + 1)
        .forEach(checkbox => checkbox.checked = this.lastChecked.checked)
      this.lastChecked = control
    }

    // Multiselect by ctrl
    if (controlKey) {
      this._changeRadioToCheckbox(inputs)
      control.checked = ! control.checked
      if (control.checked) this.lastChecked = control
    }

    // Change input checkbox radio to input ratio
    if (!e.shiftKey && !controlKey) {
      this._shortcutsChangeToRadioInput(inputs)
      this.lastChecked = control
    }

    if (this.multiSelection) {
      selection = inputs.filter((elem) => (elem.checked))
      // If focus is on deselected element, focus somewhere else to avoid the
      // focus background
      if (selection.length > 0 && !selection.includes(document.activeElement)) {
        selection[0].focus()
      }
    }
    return this._dispatchEvent(control.ownerDocument, selection, e)
  }

  _keyboardSelect(e, index){
    e.stopPropagation()
    e.preventDefault()

    let inputs = Array.from(this.root.querySelectorAll(`[name=${this.name}]`))

    if (e.ctrlKey || e.shiftKey) this._changeRadioToCheckbox(inputs)
    else this._shortcutsChangeToRadioInput(inputs)

    let focusedLabel = this.root.querySelector(`[name=${this.name}] + label:focus`)
    let focusedItem = focusedLabel ? focusedLabel.control : this.root.querySelector(`[name=${this.name}]:focus`)

    if (focusedItem == null) {
      if (inputs.length > 0) this._selectItem(inputs[0])
      return
    }

    let checkedIndex = Array.from(inputs).findIndex(x => x == focusedItem)
    let newIndex = checkedIndex + index
    let newItem = null

    while (true) {
      newItem = (newIndex >= 0 && newIndex < inputs.length) ? inputs[newIndex] : null
      let newLabel = newItem ? newItem.labels[0] : null

      if (newItem == null) {
        // no item to select beyond that, stop and return
        return
      } else if (newLabel.offsetWidth == 0 && newLabel.offsetHeight == 0) {
        // selected item is not visible, skip to next
        newIndex = newIndex + index
        continue
      } else {
        // new selected item found, stop
        break
      }
    }

    let selection = [newItem]

    if (e.ctrlKey) {
      this._focusItem(newItem)
      if (index == 0) {
        this._setChecked(newItem, !newItem.checked)
      }
    } else if (e.shiftKey) {
      if (newItem.checked) {
        this._deselectItem(focusedItem)
        this._focusItem(newItem)
      } else {
        this._selectItem(newItem)
      }
    } else {
      this._selectItem(newItem)
    }

    this.lastChecked = newItem

    if (e.ctrlKey || e.shiftKey) {
      selection = inputs.filter((elem) => (elem.checked))
    }

    return this._dispatchEvent(newItem.ownerDocument, selection, e)
  }

  // Change the checked value of an input element, and ensure by an event
  // handler that the click event will never propagate and will never be
  // catched.
  _setChecked(item, checked){
    if (item.checked == checked) return
    item.addEventListener('click', e => {e.preventDefault(); e.stopImmediatePropagation()}, {once: true, capture: true})
    item.checked = checked
  }

  _selectItem(item){
    this._setChecked(item, true)
    this._scrollbarFollowSelectItem(item)
    item.labels[0].focus()
  }

  _deselectItem(item){
    this._setChecked(item, false)
  }

  _focusItem(item){
    item.labels[0].focus()
    this._scrollbarFollowSelectItem(item)
  }

  _scrollbarFollowSelectItem(item){
    let li = $(item.closest('li'))
    let parent = $(item.closest('.scrollbar'))
    let scrollTop = li.offset().top - (parent.height() + li.height())

    // Scrollbar follow
    if (li.offset().top > parent.height()){
      parent.scrollTop(parent.scrollTop() + li.height())
    }
    if (li.offset().top < parent.offset().top){
      parent.scrollTop(parent.scrollTop() - li.height())
    }
  }

  _changeRadioToCheckbox(inputs){
    if (!this.multiSelection){
      inputs.forEach(x => x.setAttribute('type', 'checkbox'))
      this.multiSelection = true
    }
  }

  _shortcutsChangeToRadioInput(inputs){
    if (this.multiSelection){
      inputs.forEach(x => {x.checked = false; x.setAttribute('type', 'radio')})
      this.multiSelection = false
    }
  }

  _dispatchEvent(document, selection, e){
    let selectionString = selection.map(x => x.value).join(',')

    let detail = { name: this.name, selection: selection, originalEvent: e }
    if (selectionString == this.oldSelection) return detail

    this.oldSelection = selectionString
    this.selection = selection
    console.log('%d selected items: %o', selection.length, selection)

    document.dispatchEvent(new CustomEvent('selected', {detail: detail}))
    document.dispatchEvent(new CustomEvent(`${this.name}-selected`, {detail: detail}))
    return detail
  }
}

export default {

  getSelectedItems(name){
    return this.getSelection(name || 'node').getSelectedItems()
  },

  initSelection(name){
    return this.getSelection(name || 'node').initSelection()
  },

  getSelection(name0){
    let name = name0 || 'node'
    if (!this.application._selections) this.application._selections = {}
    if (!this.application._selections[name]) this.application._selections[name] = new Selection(name, this.element)
    return this.application._selections[name]
  },

  selectItem(e){
    let input = e.target
    if (input.tagName != 'INPUT') {
      let label = input.closest('label')
      if (!label) return false
      input = label.control
    }
    return this.getSelection(input.name).selectionClick(e)
  },

  selectKeydown(e){
    return this.getSelection('node').selectionKeydown(e)
  }

}
