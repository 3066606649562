import ApplicationController from '../application_controller'
import SelectionMixin from '../concerns/selection_mixin'
import { Turbo } from '@hotwired/turbo-rails'

export default class InterfaceController extends ApplicationController {

  connect(){
    super.connect()

  }


  get version(){
    return this.data.get('version')
  }

  get rootItem(){
    return this.data.get('root-item')
  }

  get rootId(){
    let item = this.rootItem
    return item ? item.id : null
  }


  // ===========================================================================
  //
  // Handler (movable split between panes)
  //

  showHandler(e){
    if (e.target.classList.contains('tree-handler')){
      document.querySelector('.overview').classList.add('tree-handler-over')
    } else if (e.target.classList.contains('details-handler')){
      document.querySelector('.details').classList.add('details-handler-over')
      document.querySelector('.overview').classList.add('overview-details-over')
    } else if (e.target.classList.contains('library-handler')){
      document.querySelector('.library').classList.add('library-handler-over')
    } else if (e.target.classList.contains('library-comparison-handler')){
      document.querySelector('.pnl-body').classList.add('library-comparison-handler-over')
    } else {
      document.querySelector('.search').classList.add('search-handler-over')
    }
  }

  hideHandler(e){
    if (e.target.classList.contains('tree-handler')){
      document.querySelector('.overview').classList.remove('tree-handler-over')
    } else if (e.target.classList.contains('details-handler')){
      document.querySelector('.overview').classList.remove('details-handler-over')
      document.querySelector('.details').classList.remove('details-handler-over')
    } else if (e.target.classList.contains('library-handler')){
      document.querySelector('.library').classList.remove('library-handler-over')
    } else if (e.target.classList.contains('library-comparison-handler')){
      document.querySelector('.pnl-body').classList.remove('library-comparison-handler-over')
    } else {
      document.querySelector('.search').classList.remove('search-handler-over')
    }
  }

  get name(){
    return this.data.get('name')
  }
}
Object.assign(InterfaceController.prototype, SelectionMixin)