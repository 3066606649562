import ApplicationController from './../application_controller'

export default class extends ApplicationController {

  static values = {
    query: String
  }

  connect(){
    super.connect()

    for (const elem of this.element.querySelectorAll('[autofocus][data-autofocus=select]')) {
      elem.focus()
      elem.select()
    }

    this.spinners = []

    this.addAction('turbo:submit-end', 'resetSpinners')
  }

  select(e){
    e.target.select()
  }

  reset(){
    this.element.reset()
  }

  clear(){
    for (let elem of this.element.elements){
      if (elem.type != 'hidden') {
        elem.value = null
      }
    }
  }

  submit(){
    this.element.requestSubmit()
  }

  resetSpinners() {
    for (let button of this.spinners) {
      button.disabled = false
      button.querySelector('.spinner-border.spinner-border-sm').remove()
    }
    this.spinners = []
  }

  submitSpin(e) {
    console.log('spin', e)
    var button = e.target.closest('button')
    if (!button) {
      button = e.target.closest('select')?.closest('.input-group')?.querySelector('button')
    }
    if (button && !button.disabled) {
      setTimeout(() => {
        let spinner = document.createElement('span')
        spinner.classList.toggle('spinner-border', true)
        spinner.classList.toggle('spinner-border-sm', true)
        button.disabled = true
        button.insertBefore(spinner, button.firstChild)
        this.spinners.push(button)
      }, 100)
    }
    this.element.requestSubmit()
  }

  submitAndValidateRecordAndContinue(){
    this.element.action += this.element.action.includes('?') ? '&' : '?'
    this.element.action += 'continue_edit=true&validate_record=true'

    this.element.requestSubmit()
  }

  resetAndSubmit(){
    this.reset()
    this.submit()
  }

  clearAndSubmit(){
    this.clear()
    this.submit()
  }

  clearSearch(e){
    e.preventDefault()
    let form = e.target.closest('form')
    let input = form.querySelector('input[type=search]')

    input.value = ''
    form.requestSubmit()
  }

  queryValueChanged(){
    this.fillForm(this.queryValue)
  }

  // Takes a query string and a form element (if omitted, the controller element
  // is considered to be the form element). Split the query string in parameters
  // and updates each of the form elements with the value taken from the query
  // string.
  fillForm(query, form){
    if (!form) form = this.element

    let multiple_init = {}

    let elements = Array.from(form.elements)
    for (let [name, value] of new URLSearchParams(query)){
      let idx = elements.findIndex(elem => elem.name == name)
      if (idx < 0) {
        continue
      }
      let elem = elements[idx]
      if (elem.tagName == 'SELECT' && elem.multiple) {
        for (let opt of elem.options) {
          if (opt.value == value) opt.selected = true
          else if (!multiple_init[name]) opt.selected = false
        }
        multiple_init[name] = true
      } else {
        elem.value = value
        if (elem.tagName == 'INPUT' && elem.type == 'checkbox') elem.checked = true
      }
    }
  }

  focusNextInput(e){
    let elem = e.target
    for (;;) {
      if (elem.nextElementSibling) {
        elem = elem.nextElementSibling
      } else if (elem.firstElementChild) {
        elem = elem.firstElementChild
      } else {
        while (elem && !elem.nextElementSibling) elem = elem.parentElement
        elem = elem?.nextElementSibling
        if (!elem) return console.warn('no next input to focus')
      }
      if (elem.tagName == 'INPUT') {
        //console.log("Focus %o (next input after %o)", elem, e.target)
        elem.focus()
        return
      }
      //console.log('focus next input', elem)
    }
  }

  checkInput(e) {
    let form = e.target.closest('form')
    let elem = form.elements[e.target.dataset.checkInputName]
    if (elem.type == 'checkbox' || elem.type == 'radio') {
      elem.checked = true
    } else {
      elem.value = '1'
    }
  }

  uncheckInput(e) {
    let form = e.target.closest('form')
    let elem = form.elements[e.target.dataset.checkInputName]
    if (elem.type == 'checkbox' || elem.type == 'radio') {
      elem.checked = false
    } else {
      elem.value = ''
    }
  }
}
