import { subscribe_one } from './helper/subscribe_all'

let mixin = {

  received(data){
    console.log('NotificationsChannel received %o', data)
    if (data.notification_count != null) {
      this.controller.updateNotificationCount(data.notification_count)
    }
  },

  setRootRefId(refId){
    this.perform('set_root_ref_id', {ref_id: refId})
  },

}

export default function(controller, root_ref_id) {
  const channel = 'NotificationsChannel'
  const subscription = subscribe_one({channel, root_ref_id}, mixin)
  subscription.controller = controller
  return subscription
}
