function genId(){
  let array = new Uint8Array(16)
  crypto.getRandomValues(array)
  return btoa(array)
}

export let debug = window.lcas_debug?.includes('eventsource')

export let eventSourceId = genId()
export let eventSource

let callbacks = {}

function add_callback(channel, cb) {
  if (!callbacks[channel]) callbacks[channel] = []
  callbacks[channel].push(cb)
}

function del_callback(channel, cb) {
  if (!callbacks[channel]) return
  callbacks[channel] = callbacks[channel].filter(x => x != cb)
}

function createEventSource(event_source_url, eventSourceId){
  // Only allow subscriptions of first message received, before it's too
  // early to subscribe and events could be lost
  return new Promise((resolve, reject) => {
    let eventSource = new EventSource(`${event_source_url}/${eventSourceId}`, {
      withCredentials: true
    })

    eventSource.onmessage = (e) => {
      //console.log("EventSource message: %o", e)
      let data = JSON.parse(e.data)
      if (debug) console.log('EventSource %s from %s: %o', data.type, JSON.stringify(data.identifier), data)
      resolve(eventSource)
      for (let cb of callbacks[data.chan_id] || []) {
        cb(data)
      }
    }

    eventSource.onerror = (e) => {
      if (debug) console.error('EventSource error: %o', e)
      reject(e)
    }

    eventSource.onopen = (e) => {
      if (debug) console.log('EventSource open: %o', e)
    }
  })
}

export function subscribe(event_source_url, channel, cb) {
  if (!eventSource) eventSource = createEventSource(event_source_url, eventSourceId)

  eventSource.then((ev) => {
    fetch(ev.url, {credentials: 'include',
      method: 'POST',
      headers: { 'content-type': 'application/json', },
      body: JSON.stringify([
        {subscribe: true, identifier: JSON.parse(channel)}
      ])})
      .then(response => response.json())
      .then(json => json[0])
      .then(res => add_callback(res.chan_id, cb))
  }).catch(err => {
    console.error('EventSource error: %o', err)
  })

  return [event_source_url, channel, cb]
}

export function unsubscribe(url, channel, cb) {
  eventSource.then((ev) => {
    fetch(ev.url, {credentials: 'include',
      method: 'POST',
      headers: { 'content-type': 'application/json', },
      body: JSON.stringify([
        {unsubscribe: true, identifier: JSON.parse(channel)}
      ])})
      .then(response => response.json())
      .then(json => json[0])
      .then(res => del_callback(res.chan_id, cb))
  }).catch(err => {
    console.error('EventSource error: %o', err)
  })
}

export async function send(message, url, channel, cb) {
  const identifier = JSON.parse(channel)
  const response = await fetch(`${url}?poll-queue=32`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'content-type': 'application/json', },
    body: JSON.stringify([
      {identifier, send: message}
    ])
  })

  return response.json()
}

export let channelMixin = {
  get isEventSource() {
    return true
  },

  receivedEvent(data) {
    return this.received(data)
  },

  closeEvent(){
    return unsubscribe(...this.subscription)
  },

  perform(action, params){
    return send({ action, ...params }, ...this.subscription)
  },

  close(){
    return this.closeEvent()
  },
}

export function createChannel(event_source_url, channel, mixin){
  let chan = Object.create({
    ...channelMixin,
    ...mixin
  })
  chan.subscription = subscribe(event_source_url, channel, (data) => {
    if (data.type == 'received') {
      chan.receivedEvent(data.message)
    }
  })
  return chan
}
