import ProjectController from '../project_controller'

export default class ProjectLayoutController extends ProjectController {

  static values = {
    treeMinWidth: Number,
    detailsMinWidth: Number
  }

  connect(){
    super.connect()

    this.tree_min_width = this.data.get('tree-min-width') || 50
    this.details_min_width = this.data.get('details-min-width') || 50

    this.tree = this.element.querySelector('.c-project-layout__tree')
    this.search = this.element.querySelector('.c-project-layout__search')
    this.details = this.element.querySelector('.c-project-layout__details')
    this.overview = this.element.querySelector('.c-project-layout__overview')
    this.search_handle = this.element.querySelector('.c-project-layout__search-handle')
    this.details_handle = this.element.querySelector('.c-project-layout__details-handle')
    this.treeScroll = this.element.querySelector('.c-project-layout__tree > .panel-body')

    this.addAction('mouseover', 'showHideHandle', this.search_handle)
    this.addAction('mouseout', 'showHideHandle', this.search_handle)
    this.addAction('mouseover', 'showHideHandle', this.details_handle)
    this.addAction('mouseout', 'showHideHandle', this.details_handle)

    this.addAction('turbo:load@document', 'onSearchResults')

    this.initTreeScroll()
    this.initDetailsWidth()
    this.initSearchResize()
    this.resizeDetails()

    // TODO: replace this when PR merged
    // <https://github.com/hotwired/turbo/pull/59> and Turbo updated
    // We need to get the event after the frame loaded the new content
    this.searchObserver = new MutationObserver(mutationsList => {
      //for(let mutation of mutationsList) {
      //  if (mutation.type === "attributes" && mutation.attributeName === "src") {
      //    this.onSearchResults()
      //  }
      //}
      this.onSearchResults()
    })
    this.searchObserver.observe(this.search.querySelector('turbo-frame'), { childList: true })
    //this.searchObserver.observe(this.search.querySelector('turbo-frame'), { attributes: true })
  }

  disconnect(){
    try {
      this.searchObserver.disconnect()
    } finally {
      super.disconnect()
    }
  }

  //
  // Tree
  //

  initTreeScroll(){
    let scroll = parseInt(sessionStorage.getItem(this.getStorageName('tree-scroll')))
    this.treeScroll.scrollTop = scroll
    this.addAction('scroll', 'onTreeScroll', this.treeScroll)
  }

  onTreeScroll(e){
    sessionStorage.setItem(this.getStorageName('tree-scroll'), e.target.scrollTop)
  }

  //
  // Details
  //

  initDetailsWidth(){
    let width = sessionStorage.getItem(this.getStorageName('details-width'))
    if (width != null) this.details.style.width = `${ width }px`
  }

  resizeDetails(){
    $(this.details).resizable({
      minWidth: this.details_min_width,
      handles: {'w': this.details_handle},
      resize: (ev, ui) => {
        ui.element[0].style.left = null
      },
      stop: (u, ui) => {
        sessionStorage.setItem(this.getStorageName('details-width'), ui.size.width)
      }
    })
  }

  //
  // Search
  //

  initSearchResize(){
    let storedHeight = sessionStorage.getItem(this.getStorageName('search-height'))
    if (storedHeight != null) this.element.style.setProperty('--search-size', `${storedHeight}px`)

    $(this.search).resizable({
      handles: { w: this.search_handle },
      minWidth: 50,
      stop: (u, ui) => {
        sessionStorage.setItem(this.getStorageName('search-height'), ui.size.height)
        ui.element[0].style.height = null
        this.element.style.setProperty('--search-size', `${ui.size.height}px`)
      },
      resize: (ev, ui) => {
        ui.element[0].style.top = null
        //ui.element[0].style.height = null
        //this.element.style.setProperty('--search-size', `${this.search.clientHeight}px`)
      }
    })

    this.onSearchResults()
  }

  onSearchResults(){
    this.resizeSearch()
    this.setSearchTerms()
    this.setSearchClass()
  }

  get searchOpened() {
    return !!this.search.querySelector('.c-project-layout__search-results--opened')
  }

  setSearchClass(){
    if (this.searchOpened) {
      this.search.classList.remove('c-project-layout__search--closed')
      this.search.classList.add('c-project-layout__search--opened')
    } else {
      this.search.classList.remove('c-project-layout__search--opened')
      this.search.classList.add('c-project-layout__search--closed')
    }
  }

  setSearchTerms(){
    let inputs = Array.from(this.search.querySelectorAll('[name^="search["]'))
    let url = this.search.querySelector('turbo-frame').src

    if (url != null) {
      for (let [name, val] of new URL(url).searchParams) {
        let input = inputs.find(i => i.name == name)
        if (input) input.value = val
      }
    }
  }

  resizeSearch(){
    if (this.searchOpened) {
      $(this.search).resizable('enable')
    } else {
      $(this.search).resizable('disable')
    }
  }

  //
  // Handles
  //

  findResizableHandle(elem) {
    let handles = elem.querySelectorAll('.ui-resizable-handle')
    return handles[handles.length - 1]
  }

  showHideHandle(e){
    let matches = Array.from(e.target.classList, cls => cls.match(/^c-project-layout__(.*)-handle$/)).filter(x => x)
    if (!matches.length) return

    let component = matches[0][1]
    if (e.type == 'mouseover') {
      this.element.classList.add(`c-project-layout--${component}-resizing`)
    } else if (e.type == 'mouseout') {
      this.element.classList.remove(`c-project-layout--${component}-resizing`)
    }
  }

}

