import ApplicationController from '../application_controller'

export default class AdminActionsController extends ApplicationController {

  showCompany(e){
    e.preventDefault()
    let companies = e.target.closest('li').querySelector('ul:first-of-type')
    if (companies.classList.contains('hidden')){
      companies.classList.remove('hidden')
      e.target.closest('button').querySelector('i').classList.remove('fa-caret-right')
      e.target.closest('button').querySelector('i').classList.add('fa-caret-down')
    } else {
      companies.classList.add('hidden')
      e.target.closest('button').querySelector('i').classList.remove('fa-caret-down')
      e.target.closest('button').querySelector('i').classList.add('fa-caret-right')
    }
  }

}
