import AnalysisController from '../analysis_controller'
import AnalysisViewChannel from './../../channels/analysis_view_channel.js'
import Loader from 'lib/loader'

const debug = window.lcas_debug?.includes('analysis-tree-controller') // ?debug[analysis-tree-controller]=1

export default class extends AnalysisController {
  static targets = ['computeIcon']

  connect(){
    super.connect()
    this.channel = new AnalysisViewChannel(this, this.viewChannelRootIds)
    this.addAction('click', 'compute', this.computeIconTargets)
    this.showLoaderForComputingItems()
  }

  disconnect(){
    super.disconnect()
    this.channel.close()
  }

  get viewChannelRootIds(){
    return [document.body.dataset.rootId]
  }

  get checkboxes() {
    return this.element.querySelectorAll('input[type=checkbox]')
  }

  showLoaderForComputingItems(){
    for (const checkbox of this.checkboxes) {
      const label = checkbox.closest('label')
      if (label.dataset.computing == 'true') {
        this.showLoader(checkbox)
        // The item might have been computed after the page was generated
        // server side and before the long-poll is connected client side, in
        // which case we will miss the notification and the spinner will
        // continue forever. That's why we make a request on page load.
        setTimeout(() => this.refreshItem(checkbox), 0)
      }
    }
  }

  analysisViewChanged(data) {
    if (debug) console.log(`AnalysisViewChanel:${document.body.dataset.rootId}`, data)
    this.markAsComputed(data)
    this.refreshCurrentTable([data])
  }

  compute(e) {
    e.preventDefault()
    const computeIcon = e.currentTarget
    const label = computeIcon.closest('label')
    const id = computeIcon.dataset.id
    const type = computeIcon.dataset.type
    this.showLoader(computeIcon)

    const rootItemRefId = document.body.dataset.rootId
    const headers = {
      'x-csrf-token': $('meta[name="csrf-token"]').attr('content'),
      'content-type': 'application/json',
      accept: 'application/json'
    }

    let body = { 'query_result_set': { 'cs_item_id': rootItemRefId } }
    body['query_result_set'][type === 'indicator' ? 'indicator_id' : 'indicators_set_id'] = id
    return fetch('/analysis/query_result_sets', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers
    }).then(resp => {
      if (!resp.ok) {
        // Hide errored results sets
        console.error('Error requesting compute of indicator %o: %o', body, resp)
        this.updateItemAfterCompute(label, true)
      }
    })
  }

  async refreshItem(checkbox) {
    const label = checkbox.closest('label')
    const id = label.dataset.resultsSetId
    if (id) {
      const res = await fetch(`/analysis/query_result_sets/${id}`, { method: 'GET', headers: {
        'x-csrf-token': $('meta[name="csrf-token"]').attr('content'),
        'content-type': 'application/json',
        'accept': 'application/json'
      }})
      const json = await res.json()
      this.markAsComputed(json)
    }
  }

  markAsComputed(data) {
    if (data.status !== 'finished' && data.status !== 'error') return
    const tree_item_ids = data.tree_item_ids
    const has_error = data.status == 'error'
    for (const tree_item_id of tree_item_ids) {
      let items = document.querySelectorAll(`.c-item[data-id="${tree_item_id}"]`)
      for (const item of items) {
        const label = item.closest('label')
        this.updateItemAfterCompute(label, has_error)
      }
    }
  }

  getNewLoader(checkbox) {
    if (!checkbox.loader) {
      checkbox.loader = new Loader(checkbox.parentElement)
    }
    return checkbox.loader
  }

  showLoader(checkbox) {
    checkbox.style.display = 'none'
    checkbox.checked = false

    const label = checkbox.closest('label')

    label.querySelector('a[href]').classList.add('disabled')
    label.querySelector('i.glyph').classList.add('text-secondary')

    this.getNewLoader(checkbox).enable()
  }

  hideLoader(computeIcon) {
    computeIcon.style.display = 'inline-block'
    computeIcon.checked = false

    this.getNewLoader(computeIcon).disable()
  }

  updateItemAfterCompute(label, has_error){
    const computeIcon = label.querySelector('.c-treeview__right-col i')
    if (computeIcon) this.hideLoader(computeIcon)

    const icon = label.querySelector('i.c-analysis-tree__icon')
    if (!has_error) this.enableLink(label, icon)
    this.updateBullet(icon, has_error)
  }

  enableLink(label, icon) {
    label.querySelector('a[href]').classList.remove('disabled')
    icon.classList.remove('text-secondary')
  }

  updateBullet(icon, has_error) {
    icon.classList.remove('text-warning', 'text-danger', 'text-success', 'hidden')
    icon.classList.add(has_error ? 'text-danger' : 'text-success')
  }

  refreshCurrentTable(resultSets) {
    const lifeCycleImpactAnalysisLi = document.querySelector('.c-treeview > li, .treeview > li')
    if (lifeCycleImpactAnalysisLi.classList.contains('active')) {
      lifeCycleImpactAnalysisLi.querySelector('a').click()
    } else {
      for (const resultSet of resultSets) {
        const id = resultSet.indicator_id != null ? resultSet.indicator_id : resultSet.indicators_set_id
        for (const checkbox of document.querySelectorAll(`input[type=checkbox][value="${id}"]`)) {
          const firstLevelLi = checkbox.closest('li')
          const link = firstLevelLi.querySelector('a')
          const secondLevelLi = firstLevelLi.closest('ol').closest('li')
          if ([firstLevelLi, secondLevelLi].filter(_ => _).some(_ => _.classList.contains('active'))) {
            link.click()
            break
          }
        }
      }
    }
  }

  hideCurrentTable(checkboxes) {
    for (let checkbox of checkboxes) {
      const firstLevelLi = checkbox.closest('li')
      const secondLevelLi = firstLevelLi.closest('ol').closest('li')
      const thirdLevelLi = secondLevelLi.closest('ol').closest('li')
      if ([firstLevelLi, secondLevelLi, thirdLevelLi].filter(_ => _).some(_ => _.classList.contains('active'))) {
        document.querySelectorAll('#indicator-table_wrapper, #indicators-table_wrapper, .chart-panel').forEach(_ => _.style.display = 'none')
        document.querySelectorAll('.table-loader').forEach(_ => _.parentNode.classList.remove('d-none'))
      }
    }
  }
}
