import ApplicationController from './../application_controller'

// This controller manages the permissions relative to a cs item.

export default class extends ApplicationController {

  addPermission(e){
    e.preventDefault()
    let template = this.element.querySelector('template')
    let newRow = template.content.querySelector('tr').cloneNode(true)
    template.parentElement.insertBefore(newRow, template)
    //new UiBibz().initial()
  }
}
