import subscribe_all from './helper/subscribe_all'

let mixin = {

  received(data){
    console.log('AnalysisViewChannel received %o', data)
    this.controller.analysisViewChanged(data)
    if ((data.status == 'should_refresh') && !document.hidden) window.turboReload()
  },

}

export default function(controller, cs_item_ref_ids) {
  const channel = 'AnalysisViewChannel'
  return subscribe_all({channel, cs_item_ref_ids}, mixin, (subscription) => {
    subscription.controller = controller
  })
}
