import ApplicationController from './../application_controller'
import BackdropElementLoader from './../../src/lib/backdrop_element_loader.js'
import NotificationsChannel from './../../channels/notifications_channel.js'
import Modal from './../../src/lib/modal.js'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle' // This must be the exact same as in ui-bibz-js

export default class extends ApplicationController {

  static values = {
    url: String
  }

  connect(){
    super.connect()
    this.addAction('c-infinite-scroll-loaded', 'initScrollContent')
    this.addAction('turbo:load@document', 'onTurboLoad')
    jQuery(this.element).on('hidden.bs.dropdown', e => this.unloadNotification(e))
    this.initDropdownToggle()
    this.channel = new NotificationsChannel(this, document.body.dataset.rootId)
  }

  onTurboLoad(e){
    this.channel.setRootRefId(document.body.dataset.rootId)
  }

  initDropdownToggle(){
    let me = this
    let dropdown = this.element.querySelector('a.dropdown-toggle')
    dropdown.dataset.bsAutoClose = 'outside'
    dropdown.addEventListener('show.bs.dropdown', function () {
      me.loadNotification()
    })
    dropdown.addEventListener('hide.bs.dropdown', function () {
      me.unloadNotification()
    })
  }

  disconnect(){
    this.channel.close()
    super.disconnect()
  }

  updateNotificationCount(newCount){
    let count = this.element.querySelector('.c-notifications__unread-count')
    count.dataset.content = newCount
    count.textContent = newCount
  }

  dropdownMenu(){
    return this.element.querySelector('.dropdown-menu')
  }

  loadNotification(){
    this.element.lcasNotifBackdrop ||= new BackdropElementLoader(this.dropdownMenu())
    this.element.lcasNotifBackdrop.enablePosition = 'absolute'
    this.element.lcasNotifBackdrop.enable()
    this.element.classList.add('loading')
    fetch(`${this.urlValue}${this.urlValue.includes('?') ? '&' : '?'}root_ref_id=${document.body.dataset.rootId || ''}`)
      .then((response) => (response.text()))
      .then((text) => {
        let frag = document.createRange().createContextualFragment(text)
        this.notificationUnreadCountHTML = frag.querySelector('.c-notifications__unread-count').outerHTML
        let notifContent = this.element.querySelector('.dropdown-menu > .c-notifications__content')
        notifContent.innerHTML = frag.querySelector('.dropdown-menu').innerHTML
        this.element.classList.remove('loading')
        this.element.lcasNotifBackdrop.disable()
        this.initContent(notifContent)
      })
      .catch(err => {
        this.element.classList.remove('loading')
        this.element.lcasNotifBackdrop.error(err)
      })
  }

  unloadNotification(){
    if (this.notificationUnreadCountHTML) {
      this.element.querySelector('.c-notifications__unread-count').outerHTML = this.notificationUnreadCountHTML
      this.notificationUnreadCountHTML = undefined
    }
  }

  closeNotification(){
    this.unloadNotification()
    this.element.classList.remove('show')
    this.dropdownMenu().classList.remove('show')
  }

  initContent(element) {
    for (let tooltip of element.querySelectorAll('[data-bs-toggle="tooltip"]')) {
      new bootstrap.Tooltip(tooltip, {html: true})
    }
  }

  stateClick(e) {
    e.preventDefault()
    const item = e.target.closest('.c-notifications__item')
    if (item) {
      const m = new Modal({
        modal_dialog_classes: 'modal-lg',
        static: false,
        title: item.querySelector('.c-notifications__state-name').textContent,
        textHTML: item.querySelector('.c-notifications__error, .c-notifications__state-popup').innerHTML
      })
      m.enable()
    }
  }

  initScrollContent(e) {
    this.initContent(this.element)
  }
}
