// top-left spinner used for long-poll

let spinnerSvg, spinnerPath

export function createSpinnerElement(){
  spinnerSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  spinnerSvg.style.position = 'fixed'
  spinnerSvg.style.top = '0'
  spinnerSvg.style.left = '0'
  spinnerSvg.style.width = '16px'
  spinnerSvg.style.height = '16px'
  spinnerPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  spinnerPath.setAttribute('fill', 'none')
  spinnerPath.setAttribute('stroke', '#cccccc')
  //spinnerPath.setAttribute('stroke-opacity', '0.25')
  spinnerPath.setAttribute('stroke-width', '2')
  spinnerPath.setAttribute('stroke-linecap', 'round')
  setSpinner(0.0)
  //spinnerSvg.appendChild(spinnerPath)
  document.body.appendChild(spinnerSvg)
}

function polarToCartesian(centerX, centerY, radius, angle) {
  var angleInRadians = (angle-0.25) * 2.0 * Math.PI
  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  }
}

function setSpinner(start, end) {
  const x = 8, y = 8, radius = 4
  const startPos = polarToCartesian(x, y, radius, end)
  const endPos = polarToCartesian(x, y, radius, start)

  const largeArcFlag = Math.abs(end - start) <= 0.5 ? '0' : '1'

  const d = [
    'M', startPos.x, startPos.y, 
    'A', radius, radius, 0, largeArcFlag, 0, endPos.x, endPos.y
  ].join(' ')

  spinnerPath.setAttribute('d', d)
  spinnerSvg.innerHTML = spinnerPath.outerHTML
}

let spinnerInterval
export function spin(timeout) {
  let t = 0
  if (spinnerInterval) clearInterval(spinnerInterval)
  spinnerInterval = setInterval(() => {
    t = t + 0.04
    let progress = t / timeout
    if (progress >= 1) {
      progress = 1.0
      clearInterval(spinnerInterval)
    }
    if (progress < 0.5) {
      setSpinner(0.0, progress * 2.0)
    } else {
      setSpinner(progress * 2.0 - 1.0, 1.0)
    }
  }, 40)
}

export function stopSpin() {
  if (spinnerInterval) clearInterval(spinnerInterval)
  setSpinner(0.0, 0.0)
}

