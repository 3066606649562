import DesignController from '../design_controller'
import $ from 'jquery'

export default class DesignSearchPanelController extends DesignController {

  static targets = [ 'frame', 'name', 'results', 'filtersButton' ]

  connect(){
    super.connect()

    this.onSearchResults()

    // On initialize, if the frame is already loaded, make sure that the search
    // form is initialized with its values 9from the URL query string)
    this.updateFormFromURL()
    this.resizePanel()
    this.initFilterBtnColor()

    this.addAction('turbo:frame-load', 'onSearchResults')
    this.addAction('turbo:frame-load', 'resizePanel')
  }

  onSearchResults(){
    // Update form when the frame loads as it can happen that the frame loads
    // from a saved URL in sessionStorage
    this.updateFormFromURL()

    // Open the panel if needed
    if (this.isOpened()) {
      this.element.classList.add('open')
    } else {
      this.element.classList.remove('open')
    }
    this.resizeDropdown()
  }

  // Result panel is opened if the result template contains the results target
  // element
  isOpened(){
    return this.hasResultsTarget
  }

  // Initialize the form elements using the values from the URL query string
  updateFormFromURL(){
    if (this.hasFrameTarget && this.frameTarget.src) {
      const query = new URL(this.frameTarget.src).search

      // Wait for form_utils to do its jobs in order to rebuil select with options filled out
      return new Promise((resolve) => {
        this.nameTarget.form.dataset.formUtilsQueryValue = query
        resolve()
      }).then(() => {
        const selects = this.nameTarget.form.getElementsByTagName('select')
        return Promise.all(
          Array.from(selects).map((elem) => {
            // console.log("[design-search-panel] DEBUG Select rebuild")
            return new Promise((resolve) => {
              $(elem).multiselect('rebuild')
              resolve()
            })
          })
        )
      }).then(() => {
        this.initFilterBtnColor()
      })
    }
  }

  // Caculate the height of the whole search panel and decrease this previous height
  // in order that the tracking dropdown stays scrollable in the window
  resizeDropdown() {
    const searchPanelHeight = this.element.offsetHeight
    const dropdownMenu = this.element.querySelector('.dropdown-tracking > .dropdown-menu.dropdown-menu-end')
    dropdownMenu.style.setProperty('max-height', `calc(100vh - 5rem - ${searchPanelHeight}px)`)
    dropdownMenu.style.setProperty('min-height', '5rem')
  }

  // Avoid the search panel wo results to have the stored height
  // Tell the resize panel controller if the panel is opened or not, it will manage the height itself
  resizePanel() {
    this.element.dataset.resizePanelOpenedValue = this.isOpened().toString()
  }

  // Make the filter button in the "active" (blue) or "inactive" (grey) color
  // depending on the checked input or select - available after the rebuild
  initFilterBtnColor() {
    if (!this.hasFiltersButtonTarget) return

    const form = this.filtersButtonTarget.closest('form')

    // Check if at least one input is checked (for checkbox, radio, etc.)
    let isInputChecked = form.querySelector('input[type="checkbox"]:checked, input[type="radio"]:checked') !== null

    // Check if a select element has a value
    let isSelectSelected = form.querySelector('select option:checked') !== null

    let filterBtn = this.filtersButtonTarget.querySelector('button:first-child')
    // Final check for at least one being true
    if (isInputChecked || isSelectSelected) {
      filterBtn.classList.add('btn-primary')
      filterBtn.classList.remove('btn-secondary')
    } else {
      filterBtn.classList.add('btn-secondary')
      filterBtn.classList.remove('btn-primary')
    }
  }

}