import ContextMenuBaseController from '../utils/context_menu_base_controller'
import AdminActions from '../../src/admin/actions.js'

export default class AdminContextMenuController extends ContextMenuBaseController {
  static targets = [
    'editLine',
    'editItem',
    'editManager',
    'deleteItem',
    'duplicate',
    'editExpirationDate'
  ]

  connect() {
    super.connect()

    this.addAction('keydown@document', 'handleShortcuts')

    let act_def = {
      'editLine':           this.editLineTarget,
      'editItem':           this.editItemTarget,
      'editManager':        this.editManagerTarget,
      'deleteItem':         this.deleteItemTarget,
      'duplicate':          this.duplicateTarget,
      'editExpirationDate': this.editExpirationDateTarget
    }

    let actions = new AdminActions(this, [], {toolbar: false, menu: true})
    actions.updateActions(act_def)

    this.bindActions(actions)
  }

  showNodeContextMenu(e){
    if (!this._handleSelection(e)) return false
    let menu = document.getElementById('node-context-menu')
    this._showMenu(menu)
    this.updateActions(menu)
    this._placeMenu(e.clientX, e.clientY, menu)
  }

  updateActions(){
    let selectedItems = this.selection || this.getSelectedItems()
    let currentItem = this.getController('current-view').currentItem

    this.actions.updateItems(selectedItems, currentItem)

    if (selectedItems.length > 0) {
      let node = selectedItems[0]
      let id = node.getAttribute('value')
      let type = node.getAttribute('data-type')
      let ids = Array.from(selectedItems, i => i.value)

      this.editItemTarget.setAttribute('href', this._buildAdminUrl(type, id, 'edit'))
      this.editManagerTarget.setAttribute('href', this._buildAdminMultiUrl('user', ids, 'edit_manager'))
      this.duplicateTarget.setAttribute('href', this._buildMakeUrlAdmin('user', id ,'duplicate'))
      this.deleteItemTarget.setAttribute('href', this._buildAdminMultiUrl(type, ids, 'destroy'))
      this.editExpirationDateTarget.setAttribute('href', this._buildAdminMultiUrl('user', ids, 'edit_expiration_date'))
    }
  }


  handleShortcuts(e){
    if (this.isInputKeydown(e)) return
    this.updateActions()

    if (e.key == 'e' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.editLineTarget)
    }

    if (e.key == 'E' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.editItemTarget)
    }

    if (e.key == 'D' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.duplicateTarget)
    }

    if (e.key == 'Delete' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.deleteItemTarget)
    }

    if (e.key == 'M' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.editManagerTarget)
    }

    if (e.key == 'X' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.editExpirationDateTarget)
    }

    return false
  }

}