import AnalysisController from '../analysis_controller'

export default class extends AnalysisController {

  changeChart(e){
    console.log('[analysis-charts] changeChart')
    document.querySelectorAll('.charts > div').forEach(x => x.classList.add('d-none'))
    document.querySelector(`.charts .${ e.target.value }`).classList.remove('d-none')
  }

}
