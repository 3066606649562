import ApplicationController from '../application_controller'
import BackdropElementLoader from '../../src/lib/backdrop_element_loader.js'

export default class extends ApplicationController {

  connect(){
    this.frame = this.element.closest('turbo-frame')
    this.addAction('turbo:before-fetch-request', 'setLoader')
    this.addAction('turbo:before-fetch-response', 'unsetLoader')
  }

  setLoader(ev) {
    this.frame.lcasBackdrop ||= new BackdropElementLoader(this.frame)
    this.frame.lcasBackdrop.enable()
  }

  unsetLoader(ev) {
    this.frame.lcasBackdrop?.error()
  }
}

