import Modal from '../lib/modal.js'

export default class ProjectImporter {

  constructor(parent_ref_id, reload){
    this.parent_ref_id = parent_ref_id
    this.reload = reload
  }

  import(){
    let loading_modal
    this._openFile().then(async file => {
      loading_modal = new Modal({ loader: true })
      loading_modal.modalElement.addEventListener('shown.bs.modal', () => loading_modal.btnOk.focus())
      await loading_modal.enable()
      if (!file) return
      let formData = new FormData()
      let headers = new Headers()
      headers.append('x-csrf-token', $('meta[name="csrf-token"]').attr('content'))
      formData.append('import_file', file)
      return fetch(this.url, {
        method:  'POST',
        body:    formData,
        headers: headers,
      })
    }).then((response) => response.json()).then(async (data) => {
      loading_modal.disable()
      if (data.message === 'success') return
      const error_modal = new Modal({ text: data.message, okText: I18n.t('cancel') })
      error_modal.modalElement.addEventListener('shown.bs.modal', () => error_modal.btnOk.focus())
      await error_modal.enable()
      const resp = await error_modal.interacted()
      if (!resp) return
    })
  }

  get url(){
    return `/make/case-studies/import?parent_ref_id=${this.parent_ref_id}`
  }

  _openFile(){
    return new Promise((ok, err) => {
      let input = document.createElement('input')
      input.style.display = 'none'
      input.setAttribute('type', 'file')
      input.addEventListener('change', (e) => {
        let file = e.target.files[0]
        ok(file)
      }, false)

      document.body.appendChild(input)
      input.click()
      document.body.removeChild(input)
    })
  }

}

