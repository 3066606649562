import ApplicationController from '../application_controller'
import $ from 'jquery'

const debug = false

export default class UiOpenSelectController extends ApplicationController {

  connect(){
    super.connect()
    this.select = this.element
    this.init()
    $(this.select).multiselect('refresh')
  }

  init(){
    this.container = this.element.parentElement
    this.dropdownMenu = this.element.parentElement.querySelector('.dropdown-menu')
    this.dropdownMenu.innerHTML = `
      <div class="dropdown-header">
        <div class="input-group input-group-sm">
          <input type="text" class="form-control form-control-sm" style="display: inline" />
          <a class="btn btn-outline-primary btn-sm">
            <i class="glyph fas fa-plus"></i>
          </a>
        </div>
      </div>
      <hr class="dropdown-divider"/>` + this.dropdownMenu.innerHTML
    this.input = this.dropdownMenu.querySelector('input')
    this.button = this.dropdownMenu.querySelector('a')
    this.input.onkeypress = this.keyup.bind(this)
    this.button.onclick = this.addItem.bind(this)
  }

  keyup(e){
    if (e.key == 'Enter') {
      this.addItem(e)
    }
  }

  findOption(value){
    for (let opt of this.select.childNodes) {
      if (opt.value == value) return value
    }
    return null
  }

  addItem(e){
    e.preventDefault()
    const value = this.input.value
    const existingOption = this.findOption(value)
    if (existingOption) {
      $(this.select).multiselect('select', existingOption, true)
      this.input.value = ''
    } else {
      const option = document.createElement('option')
      option.textContent = value
      option.value = value
      option.selected = true
      this.select.insertBefore(option, this.select.firstChild)
      $(this.select).multiselect('createOptionValue', option)
      $(this.select).multiselect('refresh')
      this.input.value = ''
      //this.refresh()
    }
  }

  refresh(){
    $(this.select).multiselect('rebuild')
    //$(this.select).multiselect('buildDropdownOptions')
    this.init()
  }
}

