import ApplicationController from '../application_controller'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import $ from 'jquery'
import markdown from 'bootstrap-markdown/js/bootstrap-markdown'

export default class BootstrapController extends ApplicationController {

  connect(){
    for (let elem of this.querySelectorAll('textarea[data-provide="markdown"]')) {
      elem.bsMarkdown = $(elem).markdown()
    }
    for (let elem of this.querySelectorAll('[data-bs-toggle="popover"]')) {
      elem.bsPopover = new bootstrap.Popover(elem)
    }
    for (let elem of this.querySelectorAll('[data-bs-toggle="tooltip"]')) {
      elem.bsTooltip = new bootstrap.Tooltip(elem, {html: true})
    }
  }

  querySelectorAll(selector) {
    let elem = this.element.matches(selector) ? [this.element] : []
    return [ ...elem, ...this.element.querySelectorAll(selector) ]
  }

}

