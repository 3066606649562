export default class {

  constructor(element = null){
    this.element = element
    const div = document.createElement('div')
    div.innerHTML = `
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    `
    this.loader = div.firstElementChild
  }

  resize(size) {
    this.loader.style.width = size
    this.loader.style.height = size
    return this
  }

  get style() {
    return this.loader.style
  }

  disable(){
    this.loader.remove()
    return this
  }

  enable(element = null){
    (element || this.element).append(this.loader)
    return this
  }

}
