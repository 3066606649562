import ToolbarBaseController from '../utils/toolbar_base_controller'
import ProjectActions from '../../src/project/actions.js'
import PopoverFrame from '../../src/lib/popover_frame.js'

export default class extends ToolbarBaseController {
  static targets = [
    'addProject',
    'addFolder',
    'addCaseStudy',

    'cutItem',
    'copyItem',
    'pasteItem',
    'duplicate',

    'import',

    'archive',
    'unarchive',
  ]

  static values = {
    teamManager: Boolean
  }

  get teamManagerValue(){
    return this.data.get('team-manager') == 'true'
  }

  connect(){
    super.connect()

    let team_manager = this.teamManagerValue

    let act_def = {
      'cutItem':            this.cutItemTarget,
      'import':             this.importTarget,
      'pasteItem':          this.pasteItemTarget,
      'copyItem':           this.copyItemTarget,
      'duplicate':          this.duplicateTarget,
      'archive':            team_manager ? this.archiveTarget : null,
      'unarchive':          team_manager ? this.unarchiveTarget : null,
    }
    let actions = new ProjectActions(this, [], {toolbar: 'node'}, null, team_manager)
    actions.updateActions(act_def)
    this.bindActions(actions)
  }

  showClipboard(e){
    const url = Routes.user_clipboard_index_path(document.body.dataset.lcasUserId)
    e.preventDefault()

    this.clipboardPopover ||= new PopoverFrame('project-toolbar-clipboard', url, e.target, {
      title: I18n.t('project.popovers.show-clipboard.title')
    })
    this.clipboardPopover.show()

  }

}
