import ApplicationController from '../application_controller'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import $ from 'jquery'

const debug = false

export default class UiModuleSelectorController extends ApplicationController {

  static targets = [ 'button', 'inputId', 'inputExtId', 'inputExtVersion', 'textInput', 'template' ]
  static values = {
    rootRefId: String,
    formSubmit: String,
    formPath: String,
    formPathParams: String
  }

  connect(){
    super.connect()

    this.proxy = this.element.dataset.baseId
    if (this.proxy) return

    this.modalElement = document.querySelector(`#modal-${this.element.id}`)
    if (!this.modalElement) {
      document.body.insertBefore(this.templateTarget.content, null)
      this.modalElement = document.querySelector(`#modal-${this.element.id}`)
      this.modalElement.remove()
      this.modalElement.dataset.baseId = this.element.id
      this.modalElement.dataset.controller = this.element.dataset.controller
    }

    this.modal = bootstrap.Modal.getOrCreateInstance(this.modalElement)

    this._cleanModal = this.cleanModal.bind(this)
    this.modalElement.addEventListener('hidden.bs.modal', this._cleanModal)
  }

  get baseController() {
    let baseElement = document.getElementById(this.element.dataset.baseId)
    return this.getController(this.element.dataset.controller, baseElement)
  }

  disconnect(){
    if (!this.proxy) {
      this.modalElement.removeEventListener('hidden.bs.modal', this._cleanModal)
    }
    super.disconnect()
  }

  cleanModal(){
    for (let frame of this.modalElement.querySelectorAll('turbo-frame')) frame.remove()
  }

  closeModal(){
    this.modal.hide()
    this.cleanModal()
  }

  openModal(e){
    this.modalElement.dataset.baseId = this.element.id
    this.modal.show()
    if (!this.modalElement.querySelector('turbo-frame')) {
      const frame = document.createElement('turbo-frame')
      frame.id = `turbo-frame-${this.element.id}`
      frame.setAttribute('src', Routes.ui_library_module_path(this.rootRefIdValue, {
        selected_item_id:          this.hasInputIdTarget ? this.inputIdTarget.value : null,
        selected_item_ext_id:      this.hasInputExtIdTarget ? this.inputExtIdTarget.value : null,
        selected_item_ext_version: this.hasInputExtVersionTarget ? this.inputExtVersionTarget.value : null,
        frame: frame.id}))
      this.modalElement.querySelector('.modal-body').insertBefore(frame, null)
    }
  }

  selectDbItem(e){
    if (this.proxy) {
      return this.baseController.selectDbItem(e)
    }

    const label = e.target.closest('label')
    const dbItemId = label.dataset.id
    const dbItemExtId = label.dataset.extId
    const dbItemExtVersion = label.dataset.extVersion
    this.textInputTarget.value = label.dataset.name
    if (this.hasInputIdTarget) this.inputIdTarget.value = dbItemId
    if (this.hasInputExtIdTarget) this.inputExtIdTarget.value = dbItemExtId
    if (this.hasInputExtVersionTarget) {
      const option = document.createElement('option')
      option.textContent = dbItemExtVersion
      this.inputExtVersionTarget.replaceChildren(option)
    }
    this.closeModal()

    // Submit the form if configured so
    const form = this.element.closest('form')
    if (form && this.hasFormSubmitValue) {
      form.action += form.action.includes('?') ? '&' : '?'
      form.action += this.formSubmitValue
      form.requestSubmit()
      return
    }

    // Update form depending on the db item chosen
    const form_root = this.element.closest('.c-ui-module-selector__form-root')
    const frame = form_root ? form_root.querySelector('.c-ui-module-selector__form-frame') : null
    if (frame) {
      frame.src = Routes[this.formPathValue]({
        frame: frame.id,
        ...JSON.parse(this.formPathParamsValue),
        db_item_id: dbItemId
      })
    }
  }

}

