import InterfaceController from '../utils/interface_controller'

export default class extends InterfaceController {

  connect(){
    this.addTurboBlacklistedLinks()
    //this.setupNodeSelection()
  }

  get version(){
    return this.data.get('version')
  }

  showHandler(e){
    document.querySelector('#view').classList.add('tree-handler-over')
  }

  hideHandler(e){
    document.querySelector('#view').classList.remove('tree-handler-over')
  }

  changeActiveTab(e){
    let inputs = document.querySelectorAll('input[name=active_tab]')
    if (inputs.length > 0){
      inputs.forEach(function(x){
        let target = e.target.tagName == 'A' ? e.target : e.target.closest('a')
        x.value = target.getAttribute('href').substring(1)
      })
    }
  }

}
