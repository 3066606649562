let debug = window.lcas_debug?.includes('loader') // ?debug[loader]=1

export default class {

  constructor(element, enable = false){
    this.element = element
    const div = document.createElement('div')
    div.innerHTML = `
    <div class="c-element-backdrop fade show">
      <div class="spinner-border" style="width: min(var(--spinner-size), 5rem); height: min(5rem, var(--spinner-size));" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <i class="glyph-info glyph fas fa-ellipsis" style="font-size: min(var(--spinner-size), 5rem);" role="status"></i>
    `
    this.enablePosition = 'relative'
    this.backdrop = div.firstElementChild
    this.backdropObserver = new ResizeObserver(entries => {
      this.setSpinnerSize()
    })
    this.errorElement = this.backdrop.nextElementSibling
    this.spinnerElement = this.backdrop.querySelector('.spinner-border')
    if (enable) this.enable()
  }

  error(message = ''){
    if (debug) console.log('backdrop-element-loader: error on %o', this.element)
    setTimeout(() => {
      this.spinnerElement.remove()
      this.errorElement.setAttribute('title', message)
      this.backdrop.append(this.errorElement)
    }, 2500)
  }

  disable(){
    if (debug) console.log('backdrop-element-loader: disable on %o', this.element)
    this.backdrop.remove()
    this.backdropObserver.unobserve(this.backdrop)
    for (let prop in this.old) this.element.style[prop] = this.old[prop]
  }

  enable(){
    if (debug) console.log('backdrop-element-loader: enable on %o', this.element)

    this.errorElement.remove()
    this.backdrop.append(this.spinnerElement)

    // Save old style properties
    this.old = {
      position: this.element.style.position,
      borderTopLeftRadius: this.element.style.borderTopLeftRadius,
      borderTopRightRadius: this.element.style.borderTopRightRadius,
      borderBottomLeftRadius: this.element.style.borderBottomLeftRadius,
      borderBottomRightRadius: this.element.style.borderBottomRightRadius,
    }

    // Style the parent element
    const computed = getComputedStyle(this.element)
    this.element.style.position = this.enablePosition
    this.element.style.borderTopLeftRadius = computed['border-top-left-radius']
    this.element.style.borderTopRightRadius = computed['border-top-right-radius']
    this.element.style.borderBottomLeftRadius = computed['border-bottom-left-radius']
    this.element.style.borderBottomRightRadius = computed['border-bottom-right-radius']

    // Add the backdrop and loader
    this.element.append(this.backdrop)

    // Set spinner size
    this.setSpinnerSize()
    this.backdropObserver.observe(this.backdrop)
  }

  setSpinnerSize() {
    let backdropSize = Math.min(this.backdrop.clientWidth, this.backdrop.clientHeight) / 2
    if (backdropSize >= 10) this.backdrop.style.setProperty('--spinner-size', `${backdropSize}px`)
  }

}
