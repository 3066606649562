import ApplicationController from '../application_controller'

// radio-display controller
// ========================
//
// Show or hide elements depending on radio button selection
//
// The controller should be attached to a parent element of both the radio
// buttons and the elements that are going to be shown or hidden.
//
// attributes:
//
//   - data-controller="radio-display": attaches the controller
//   - data-radio-display-name-value: the name attribute of the radio button
//     that triggers the display
//
// child radio button:
//
//   - name: the name attributes attaches the radio button to the controller
//   - value: allows to configure the shown or hidden elements
//
// child elements to show or hide
//
//   - class="c-radio-display--item" selects the elements to be shown or hidden
//     by this controller
//   - class="c-radio-display--item-${name}" selects the element to be shown or
//     hidden and specify which radio button name it is triggered by
//   - class="c-radio-display--value-${value}" will allow the element to be
//     shown only of the given value is currently selected

const debug = window.lcas_debug?.includes('radio-display') // ?debug[radio-display]=1

export default class RadioDisplayController extends ApplicationController {
  static targets = []
  static values = {
    name: String
  }

  connect(){
    super.connect()

    const selector = `[name="${this.nameValue}"]`
    if (debug) console.log('radio-display init: examine all %s', selector, this.element.querySelectorAll(selector))
    for (let elem of this.element.querySelectorAll(selector)) {
      if (debug) console.log('radio-display init: examine %o', elem)
      this.addAction('change', 'radioChanged', elem)
      if (elem.checked) this.selectValue(elem.value)
    }
  }

  radioChanged(e){
    if (debug) console.log('radio-display change(%o): %s == %s', e, e.target.name, this.nameValue)
    if (e.target.name === this.nameValue) {
      this.selectValue(e.target.value)
    }
  }

  selectValue(value){
    const selectors = [
      `[class~="c-radio-display--item-${this.nameValue}"]`,
      '[class~="c-radio-display--item"]'
    ]

    for (let selector of selectors) {
      let elems = this.element.querySelectorAll(selector)

      if (debug) console.log('radio-display value: %o, query all %s', value, selector, elems)
      if (elems.length == 0) continue

      this.selectValueElems(value, elems)
      return
    }
  }

  selectValueElems(value, elems) {
    for (let elem of elems) {
      if (elem.classList.contains(`c-radio-display--value-${value}`)) {
        if (debug) console.log('radio-display show %o', elem)
        elem.classList.remove('hidden')
      } else {
        if (debug) console.log('radio-display hide %o', elem)
        elem.classList.add('hidden')
      }
    }
  }

}


