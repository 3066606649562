export class Selection {

  constructor() {
    for (const control of document.querySelectorAll('input[type=checkbox]')) {
      if (!control.checked) continue
      const name = control.name
      this[name] = control
      this[`${name}s`] ||= []
      this[`${name}s`].push(control)
    }
    for (const control of document.querySelectorAll('input[type=radio]')) {
      if (!control.checked) continue
      const name = control.name
      this[`${name}s`] ||= []
      this[`${name}s`].push(control)
      this[name] = (this[`${name}s`].length == 1) ? this[`${name}s`][0] : null
    }
    for (const view of document.querySelectorAll('[data-current-view-ref-id-value]')) {
      const ref_id = view.dataset.currentViewRefIdValue
      for (const control of document.querySelectorAll(`input[value="${ref_id}"]`)) {
        const name = control.name
        this[`current_${name}`] = control
      }
    }
  }

}
