import DesignController from '../design_controller'
// IMPORTANT: use the same bootstrap import path throught the application and be
// coherent with ui-bibz-js. No need to use the minified version as all the
// javascript will be minified in post-process.
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'

export default class extends DesignController {
  static targets = ['displayPopoverMenu']

  connect () {
    this.show_gross = true
    this.show_percent = true
    this.addAction('lcas-analysis-column-visibility-change', 'showHideIndicator')
  }

  fixFooterGap() {
    $('.DTFC_LeftFootWrapper').attr('style', function(_, s) { return (s || '') + `top: ${parseInt($('.DTFC_LeftFootWrapper').css('top')) + 1}px !important;` })
  }

  openFormPopup(e){
    e.preventDefault()
    this.openMakeForm(e.target.closest('a').href, (form) => {})
  }

  tableviewOptionsMenuToggle(){
    this.displayPopoverMenuTarget.classList.toggle('hidden')
  }

  expandTable() {
    document.getElementById('tree').classList.toggle('hidden')
    window.analysisTable.draw()
    this.fixFooterGap()
  }

  exportCsv(){
    window.analysisTable.buttons()[0].node.click()
  }

  exportExcel(){
    window.analysisTable.buttons()[1].node.click()
  }

  downloadExcel(e){
    let url = e.target.closest('a').href
    e.preventDefault()
    url += (url.includes('?')) ? '&' : '?'
    url += `show_gross=${this.show_gross}&`
    url += `show_percent=${this.show_percent}&`
    window.location = url
  }

  exportPdf(){
    window.analysisTable.buttons()[2].node.click()
  }

  exportPrint(){
    window.analysisTable.buttons()[3].node.click()
  }

  copy(){
    window.analysisTable.buttons()[4].node.click()
  }

  showGrossValues(){
    window.analysisTable.columns('th.percent').visible(false)
    window.analysisTable.columns('th.gross').visible(true)
    this.show_percent = false
    this.show_gross = true
  }

  showPercentValues(){
    window.analysisTable.columns('th.gross').visible(false)
    window.analysisTable.columns('th.percent').visible(true)
    this.show_gross = false
    this.show_percent = true
  }

  showPercentAndGrossValues(){
    window.analysisTable.columns('th.gross').visible(true)
    window.analysisTable.columns('th.percent').visible(true)
    this.show_gross = true
    this.show_percent = true
  }

  hideFlowPath(e) {
    e.target.form.submit()
  }

  hideZeroValues(e) {
    const toolbar = e.target.closest('.analysis-toolbar__values-toolbar')
    for (const control of toolbar.querySelectorAll('.analysis-toolbar--disable-unless-hide-zeros')) {
      control.disabled = e.target.checked
    }
    e.target.form.submit()
  }

  displayFullIndicatorsNames(e) {
    console.log('[analysis-toolbar] displayFullIndicatorsNames')
    this.updateChart()
  }

  showHideIndicator(e){
    this.updateTable(`.${e.detail.value}`, e.detail.visible)
    this.updateChart()
  }

  letDropdownOpen(e){
    let dropdownButton = e.target.closest('.dropdown').querySelector('[data-bs-toggle=dropdown]')
    let dropdown = new bootstrap.Dropdown(dropdownButton)

    dropdown.show()
  }

  updateChart(target){
    if (!window.analysisChart) return

    console.log('[analysis-toolbar] updateChart', target)

    const fullNames = this.element.querySelector('#graph_label_key')?.checked
    const columnCheckboxes = this.element.querySelector('.c-analysis-column-checkbox').parentElement
    const checkedInputs = columnCheckboxes.querySelectorAll('.c-analysis-column-checkbox input[type=checkbox]:checked')
    const checkedNames = Array.from(checkedInputs, input => input.dataset.name)

    // Replace chart data with origin data
    window.analysisChart.data = $.extend(true, {}, window.analysisChartData)

    let newData = $.extend(true, {}, window.analysisChartData)

    // Remove data belongs to an indicator
    window.analysisChart.data.datasets.forEach((dataset, i) => {
      newData.datasets[i].data = dataset.data.filter(item => checkedNames.includes(item.x))
    })

    window.analysisChart.data = newData

    // Set labels
    window.analysisChart.data.labels = Array.from(checkedInputs).map(input => fullNames ? input.dataset.label : input.dataset.name)

    window.analysisChart.update()
  }

  updateTable(columnNames, visible){
    // Hide Column in the table
    if (window.analysisTable) window.analysisTable.columns(columnNames).visible(visible)
  }

  showChartsOverview(_e){
    let height = $('.tree-body').outerHeight() - $('.btn-toolbar').outerHeight()

    document.querySelector('.values-button').classList.remove('d-none')
    document.querySelector('.charts-button').classList.add('d-none')
    document.querySelector('.tab-pane.values').classList.remove('active')
    document.querySelector('.tab-pane.charts').classList.add('active')
    document.querySelector('.chart-panel').style.height = `${height}px`
  }

  showValuesOverview(_e){
    document.querySelector('.values-button').classList.add('d-none')
    document.querySelector('.charts-button').classList.remove('d-none')
    document.querySelector('.tab-pane.values').classList.add('active')
    document.querySelector('.tab-pane.charts').classList.remove('active')
    window.analysisTable.draw()
    window.dispatchEvent(new Event('resize'))
    this.fixFooterGap()
  }

  downloadChart(e){
    let canvas = document.querySelector('.chart-panel canvas')
    let url_base64jp = canvas.toDataURL('image/jpg')
    let a = e.target
    a.href = url_base64jp
  }
}
