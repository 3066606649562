import ApplicationController from '../application_controller'
import * as timeago from 'timeago.js'
import timeagoLocaleFr from 'timeago.js/lib/lang/fr'

export default class TimeagoController extends ApplicationController {

  connect(){
    super.connect()
    timeago.register('fr', timeagoLocaleFr)
    timeago.render(this.element, document.documentElement.lang)
    console.log('timeago', this.element)
  }

}

