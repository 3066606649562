import { iface } from '../src/iface.js'
import { application } from 'controllers/application'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const designControllers = require.context('../controllers/design', true, /controller\.js$/)
const projectControllers = require.context('../controllers/project', true, /controller\.js$/)
const databaseControllers = require.context('../controllers/database', true, /controller\.js$/)
const analysisControllers = require.context('../controllers/analysis', true, /controller\.js$/)
const comparisonControllers = require.context('../controllers/comparison', true, /controller\.js$/)
const adminControllers = require.context('../controllers/admin', true, /controller\.js$/)
const utilsControllers = require.context('../controllers/utils', true, /controller\.js$/)
const makeControllersShared = require.context('../controllers/make_shared', true, /controller\.js$/)
const makeControllersSpecific = require.context('../controllers/make_specific', true, /controller\.js$/)

let controllers = {
  analysis:   analysisControllers,
  comparison: comparisonControllers,
  database:   databaseControllers,
  design:     designControllers,
  project:    projectControllers,
  admin:      adminControllers,
  make:       makeControllersSpecific,
}

if (iface.name != 'devise'){
  application.load(definitionsFromContext(utilsControllers))
  application.load(definitionsFromContext(makeControllersShared))
  application.load(definitionsFromContext(designControllers))
  application.load(definitionsFromContext(projectControllers))
  application.load(definitionsFromContext(comparisonControllers))
  application.load(definitionsFromContext(analysisControllers))
  application.load(definitionsFromContext(databaseControllers))
  application.load(definitionsFromContext(adminControllers))
}
