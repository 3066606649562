console.log('Application from Webpack is loaded.')

require('./jquery.js')
import * as UiBibz from './src/ui_bibz'
require('./channels')
require.context('images', true)
//
// Analysis
window.JSZip = require('jszip')
require('./src/vendor/jquery.tableheadfixer.js')

// For Design and Analysis
require('patternomaly')

// Interface
require('./src/utils')

// Enable MathML on Google Chrome
require('./src/vendor/mspace.js')

require('./src/i18n.js')
window.Routes = require('./src/routes.js')

require('./lodash.js')


require('./src/controllers')


// Don't visit and cache blacklisted page with turbo
document.addEventListener('turbo:before-visit', function(event) {
  let url = event.detail.url.split(window.location.origin)[1]
  let isBlacklisted = window.turboBlacklist.includes(url)
  if (isBlacklisted){
    console.warn('Turbo visit to %o cancelled due to turbo blacklist, debug with ?debug[turbo-blacklist]=1', url, event)
    event.preventDefault()
    return false
  }
})

// Reload ui-bibz elements on page re-render
UiBibz.init(document)
window.addEventListener('load', (event) => {
  UiBibz.init(event.target)
})
document.addEventListener('turbo:render', (event) => {
  UiBibz.init(event.target)
})

import Loader from './src/lib/loader.js'
window.Loader = Loader

import BackdropElementLoader from './src/lib/backdrop_element_loader.js'
window.BackdropElementLoader = BackdropElementLoader

document.addEventListener('turbo:before-fetch-request', (ev) => {
  if (ev.target.tagName == 'TURBO-FRAME' && !ev.explicitOriginalTarget.dataset?.noLoader) {
    ev.target.lcasBackdrop ||= new BackdropElementLoader(ev.target)
    ev.target.lcasBackdrop.enable()
  }
})

document.addEventListener('turbo:before-fetch-response', (ev) => {
  if (ev.target.tagName == 'TURBO-FRAME') {
    ev.target.lcasBackdrop?.error()
  }
})
