import ApplicationController from '../application_controller'

// This must be the exact same as in ui-bibz-js
//import { Modal } from 'ui-bibz-js/node_modules/bootstrap/dist/js/bootstrap.bundle'
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle'

export default class extends ApplicationController {
  static targets = [
    'searchLink',
    'searchForm',
  ]

  connect(){
    super.connect()
    // this.addAction('click', 'searchClicked', this.searchLinkTarget.querySelector('a'))

    const modal = document.getElementById('modal-shortcuts')
    if (modal != null) {
      this.shortcutModal = new Modal(modal, {})
    }
  }

  // searchClicked(){
  //   this.searchFormTarget.classList.toggle('hidden')
  // }

  submitUserSettings(e){
    ap(e.target.closest('form'))
    e.target.closest('form').submit()
  }

  showShortcutModal(){
    this.shortcutModal.toggle()
  }
}
