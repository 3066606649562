import ContextMenuBaseController from '../utils/context_menu_base_controller'
import ProjectActions from '../../src/project/actions.js'

export default class extends ContextMenuBaseController {
  static targets = [
    'addProject',
    'editLine',
    'editItem',
    'deleteItem',
    'cutItem',
    'copyItem',
    'pasteItem',
    'share',
    'bookmark',
    'status',
    'reinitializeItem',
    'transformToTemplate',
    'defineGlobalTemplate',
    'archiveItem',
    'unarchiveItem',
    'duplicate',
    'typeSpecificDivider',
    'caseStudyDivider',
    'goToComparison',
    'import',
    'export',
    'exportInies',
    'divider1',
    'clipboardDivider',
    'updateModules',
    'fixBroken',
    'removeAnalysisResults',
    'copyRefId',
  ]

  static values = {
    teamManager: Boolean
  }

  get teamManagerValue(){
    return this.data.get('team-manager') == 'true'
  }

  connect(){
    super.connect()
    this.menuKind = 'node'
    this.names = ['node']

    let act_def = {
      'editLine':              this.editLineTarget,
      'editItem':              this.editItemTarget,
      'deleteItem':            this.deleteItemTarget,
      'cutItem':               this.cutItemTarget,
      'import':                this.importTarget,
      'pasteItem':             this.pasteItemTarget,
      'copyItem':              this.copyItemTarget,
      'duplicate':             this.duplicateTarget,
      'archive':               this.archiveItemTarget,
      'unarchive':             this.unarchiveItemTarget,
      'firstDivider':          this.divider1Target,
      'clipboardDivider':      this.clipboardDividerTarget,
      'typeSpecificDivider':   this.typeSpecificDividerTarget,
      'caseStudyDivider':      this.caseStudyDividerTarget,
      'addProject':            this.addProjectTarget,
      'transformToTemplate':   this.transformToTemplateTarget,
      'defineGlobalTemplate':  this.hasDefineGlobalTemplateTarget ? this.defineGlobalTemplateTarget : null,
      'bookmark':              this.bookmarkTarget,
      'share':                 this.shareTarget,
      'status':                this.statusTarget,
      'reinitializeItem':      this.hasReinitializeItemTarget ? this.reinitializeItemTarget : null,
      'goToComparison':        this.goToComparisonTarget,
      'export':                this.exportTarget,
      'updateModules':         this.updateModulesTarget,
      'fixBroken':             this.fixBrokenTarget,
      'removeAnalysisResults': this.hasRemoveAnalysisResultsTarget ? this.removeAnalysisResultsTarget : null,
      'copyRefId':             this.copyRefIdTarget,
    }
    let actions = new ProjectActions(this, [], {menu: 'node'}, null, this.teamManagerValue)
    actions.updateActions(act_def)

    this.bindActions(actions)
  }

  filterContextMenuClick(e){
    return e.detail.kind == 'item'
  }

  showNodeContextMenu(e){
    if (!this._handleSelection(e)) return false
    let menu = document.getElementById('node-context-menu')
    this._showMenu(menu)
    this.updateActions(menu)
    this._placeMenu(e.clientX, e.clientY, menu)
  }

  updateActions(menu){
    let selectedItems = this.getSelectedItems()
    let currentItem = this.getController('current-view').currentItem

    this.actions.updateItems(selectedItems, currentItem)

    if (selectedItems.length == 0) {
      this.pasteItemTarget.setAttribute('href', this._buildMultiUrl([], 'paste'))
    } else {
      let node = selectedItems[0]
      let id = node.getAttribute('value')
      let type = node.getAttribute('data-type')
      let ids = Array.from(selectedItems, i => i.value)

      if (type == 'project' && !this.teamManagerValue) return this._hideMenu(menu)

      this.addProjectTarget.setAttribute('href', this._buildMakeUrl('projects', 'new'))
      this.copyItemTarget.setAttribute('href', this._buildMultiUrl(ids, 'copy'))
      this.cutItemTarget.setAttribute('href', this._buildMultiUrl(ids, 'cut'))
      this.pasteItemTarget.setAttribute('href', this._buildMultiUrl([], 'paste', id))
      this.editItemTarget.setAttribute('href', this._buildMakeUrl(type, id, 'edit'))
      this.deleteItemTarget.setAttribute('href', this._buildMultiUrl(ids, 'multi_destroy'))
      this.statusTarget.setAttribute('href', this._buildMakeUrl('cs_item', id, 'statuses'))
      this.archiveItemTarget.setAttribute('href', this._buildMakeUrl(type, id, 'toggle-archive'))
      this.unarchiveItemTarget.setAttribute('href', this._buildMakeUrl(type, id, 'toggle-archive'))
      this.duplicateTarget.setAttribute('href', this._buildMultiUrl(ids, 'duplicate'))
      this.exportIniesTarget.setAttribute('href', this._buildMakeUrl(type, id, 'export/inies'))

      this.importTarget.setAttribute('data-import-case-study-parent-ref-id', id)
    }
  }

  handleShortcuts(e){
    if (this.isInputKeydown(e)) return
    this.updateActions()

    if (e.key == 'e' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.editLineTarget)
    }

    if (e.key == 'E' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.editItemTarget)
    }

    if (e.key == 'C' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.copyItemTarget)
    }

    if (e.key == 'X' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.cutItemTarget)
    }

    if (e.key == 'Delete' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.deleteItemTarget)
    }

    return false
  }
}
