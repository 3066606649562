import DesignController from '../design_controller'

export default class extends DesignController {

  static targets = [ 'dropdown', 'dropdown-name' ]

  connect(){
    super.connect()

    this.is_tracking = JSON.parse(this.dropdownTarget.dataset.designTrackingEnabled)
    this.addAction('click', 'trackingSelected', this.dropdownTarget)
    this.updateTrackingDropdown()
  }

  trackingSelected(e){
    // If click on header, open or close the details section and stops all actions
    if (e.target.tagName == 'SUMMARY') {
      e.target.closest('details').toggleAttribute('open')
      e.stopPropagation()
      e.preventDefault()
      return
    }
    const new_name = e.target.dataset.designTrackingButtonNameValue
    const new_title = e.target.dataset.designTrackingButtonTitleValue
    const link = e.target.closest('a')
    if (link) {
      const radio = link.querySelector('input')
      if (radio && e.target != radio) {
        // Do not toggle when clicking on the radio button becase it will
        // already be toggled by browser behavior
        radio.checked = true
      }
      const stop_tracking = link.dataset.designTrackingStopTrackingValue
      const start_tracking = link.dataset.designTrackingStartTrackingValue
      if (start_tracking) {
        this.is_tracking = true
        // Set tracking relative to radio button to the default value
        this.element.querySelector('[name="track_relative_to"][value="phase"]').checked = true
        this.updateTrackingDropdown()
      } else if (stop_tracking) {
        this.is_tracking = false
        this.updateTrackingDropdown()
      }
    }
    if (new_name && new_title) {
      this.dropdownTarget.querySelector('[data-design-tracking-target=dropdown-name]').textContent = new_name
      this.dropdownTarget.querySelector('button').title = new_title
    }
  }

  updateTrackingDropdown(){
    for (let elem of this.dropdownTarget.querySelectorAll('.if-tracking')) {
      if (this.is_tracking) {
        elem.classList.remove('hidden')
      } else {
        elem.classList.add('hidden')
      }
    }
  }

}

