// drag-source controller
// ======================
//
// Usage
// -----
//
// .scrollbar
//   ...
//     %li{'data-controller': 'drag-source'}
//       %input
//       %label{for: <input-id>}
//       ...

import ApplicationController from '../application_controller'

export default class DragSourceController extends ApplicationController {

  connect(){
    super.connect()
    this.parentContainer = this.element.closest('.scrollbar')

    // If the draggable container is placed at the body root, styling might
    // disappear from the element dragged. If the draggable container is not
    // at the page body, it risk being hidden behind other elements as it is
    // dragged.
    const dragRoot = document.body // this.parentContainer.parentElement

    this.draggable = dragRoot.querySelector('.t-draggable')
    if (!this.draggable) {
      this.draggable = document.createElement('div')
      this.draggable.classList.add('t-draggable')
      dragRoot.appendChild(this.draggable)
    }

    if (!this.node_type.startsWith('Category')) {
      $(this.element).draggable({
        'helper':            'clone',
        'appendTo':          this.draggable,
        'connectToSortable': '.overview-body .t-body',
      })
    }
  }

  get label(){
    return this.element.querySelector('label')
  }

  get input(){
    return this.element.querySelector('input')
  }

  get node_type(){
    return this.label.dataset.type
  }

  get node_id(){
    return this.label.dataset.id
  }

}

