import ApplicationController from '../application_controller'

// Toolbar
// =======
//
// This is the base class for toolbar controllers. Toolbar controllers are
// simple controllers bound on the toolbar HTML markup. They react on selection
// change to enable/disable toolbar items and handle button clicks.
//
// Implementation
// --------------
//
// You can implement the toolbar controller using an actions object. Upon
// connect, create the actions object then call `this.bindActions(actions)`. It
// will bind the toolbar items to the actions object automatically, except if
// there is no method defined on the action object.
//
// Implementation (low level)
// --------------------------
//
// To write your own toolbar controller you must implement the following
// methods:
//
// ### updateAvailableActionsFromSelection(sel, selname)
//
// This method is called on selection change and must update the
// enabled/disabled status for each toolbar button depending on which item is
// selected.
//
// Use
// ---
//
// The `openFormPopup()` event handler can be used to open a form link in a
// popup instead of redirecting the whole page to the form.
//
// `_enableBtn`, `_disableBtn` and `_hideBtn` can be used to change the state of
// toolbar buttons.
//
// this.currentItemRefId can be used to get the current item ref id

export default class extends ApplicationController {

  connect(){
    super.connect()
    this.addAction('selected@document', 'onItemSelected')
    this.addAction('current-view-changed@document', 'onCurrentItem')
    this.addAction('turbo:frame-load@document', 'updateActions')
    this._disableClickOnMenu()
  }

  bindActions(actions){
    this.actions = actions

    if (this.getController('current-view')) {
      const currentItem = this.getController('current-view').currentItem
      if (this.debug) console.log('actions.updateItems(null, %o)', currentItem)
      actions.updateItems(null, this.getController('current-view').currentItem)
    } else if (this.debug) {
      console.log('cannot update actions currentItem: current-view controller not found')
    }

    for (let _actname in actions.actions){
      let actname = _actname
      let target = actions.actions[actname]
      if (actions[actname]) {
        this[actname] = (e) => {
          e.preventDefault()
          actions[actname]({toolbar: true, menu: false})
        }
        if (target) {
          this.addAction('click', actname, target)
        } else {
          console.warn('Missing target for %s (will ignore)', actname, actions.actions)
        }
      }
    }
  }

  updateAvailableActionsFromSelection(items, selname){
    let currentView = this.getController('current-view')
    let currentItem = currentView ? currentView.currentItem : null
    if (this.actions) {
      if (this.debug) console.log('this.actions.updateItems(..., %o)', currentItem)
      this.actions.updateItems({[selname || 'node']: items}, currentItem)
    }
  }

  onItemSelected(e){
    this.updateAvailableActionsFromSelection(e.detail.selection, e.detail.name)
  }

  onCurrentItem(e){
    if (this.actions) this.actions.updateItems(null, e.detail.item)
  }

  openFormPopup(e){
    e.preventDefault()
    this.openMakeForm(e.target.closest('a').href, (form) => {})
  }

  get currentItem(){
    //throw "Deprecated, use current-view controller"
    return document.querySelector('label.current')
  }

  // Protected Methods #########################################################

  _disableClickOnMenu(){
    $('.browser-toolbar .dropdown-menu').on({
      'click':function(e){
        Rails?.handleMethod(e)
        e.stopPropagation()
      }
    })
  }

  _showBtns(btns){
    btns.forEach(btn => this._showBtn(btn))
  }

  _hideBtns(btns){
    btns.forEach(btn => this._hideBtn(btn))
  }

  _enableBtns(btns){
    btns.forEach(btn => this._enableBtn(btn))
  }

  _disableBtns(btns){
    btns.forEach(btn => this._disableBtn(btn))
  }

  _enableBtn(btn){
    btn.classList.remove('disabled')
    btn.classList.remove('hidden')
    btn.removeAttribute('disabled')
    btn.removeAttribute('hidden')
  }

  _disableBtn(btn){
    btn.classList.add('disabled')
    btn.setAttribute('disabled', true)
  }

  _hideBtn(btn){
    btn.classList.add('hidden')
    btn.setAttribute('hidden', true)
  }

  _showBtn(btn){
    btn.classList.remove('hidden')
    btn.removeAttribute('hidden')
  }

  _updateContextMenuAction(updateActions = true){
    let contextMenuController = this.getController('context-menu')
    if (updateActions) contextMenuController.updateActions()

    return contextMenuController
  }

  _updateContextMenuContainerAction(updateActions = true){
    let contextMenuController = this.getController('context-menu-container')
    if (updateActions) contextMenuController.updateActions()

    return contextMenuController
  }

  updateActions() {
    this.actions.updateActions()
  }

}
