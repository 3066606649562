import ApplicationController from './../application_controller'

export default class extends ApplicationController {

  static targets = ['submitArea', 'stepTitle']

  connect(){
    super.connect()
    this.steps = Array.from(this.element.querySelectorAll('.c-step-form__step'))
    this.navs = Array.from(this.element.querySelectorAll('.c-step-form__nav-step-item'))
    this.navs_prev = Array.from(this.element.querySelectorAll('.c-step-form__step-nav-prev'))
    this.navs_next = Array.from(this.element.querySelectorAll('.c-step-form__step-nav-next'))

    this.addAction('change', 'markFormChanged')
    this.addAction('input', 'markFormChanged')

    if (this.steps.length > 1 && (this.steps.length != this.navs.length)) {
      console.warn('Step form button mismatch %o %o', this.steps, this.navs)
    }

    this.moveStepItems()

    this.length = Math.min(this.steps.length, this.navs.length)
    let activeIdx = this.steps.findIndex( e => e.classList.contains('c-step-form--active'))
    if (activeIdx < 0) activeIdx = 0
    this._showStep(activeIdx)

    for (let nav of this.navs_prev) {
      nav.classList.add('invisible')
    }

    for (let nav of this.navs) {
      this.addAction('click', 'showClickedStep', nav.querySelectorAll('button'))
      this.addAction('click', 'showClickedStep', nav.querySelectorAll('a'))
    }
    this.addAction('click', 'showPrevStep', this.navs_prev)
    this.addAction('click', 'showNextStep', this.navs_next)
    this.addAction('turbo:frame-load', 'moveStepItems')
    this.addAction('turbo:frame-render', 'moveStepItems')

    this.addAction('click', 'closeForm', this.element.querySelectorAll('.c-step-form__close'))
    this.addAction('beforeunload@window', 'unloadWindow')
  }

  markFormChanged(e) {
    const form = e.target.closest('form')
    if (!form.dataset.changed) {
      form.dataset.changed = true
      console.log('[step-form] Mark form as changed %o', form)
    }
  }

  submitClicked(e){
    this.submitAllForms(e.target.form, e.target.name, e.target.value)
  }

  async submitAllForms(currentForm, button_name, button_value){
    let forms = [
      ...this.element.querySelectorAll('turbo-frame form'),
      ...this.element.querySelectorAll('form'),
    ]
    forms = forms.filter((x, i) => (forms.indexOf(x) == i))
    for (const form of forms) {
      let res
      if (form == currentForm) {
        res = await this.submitSingleForm(form, true, button_name, button_value)
      } else {
        res = await this.submitSingleForm(form, false)
      }
      if (!res) {
        console.log('[step-form] Form submission failed, aborting save', res)
        alert(I18n.t('front.controllers.step_form.errors_occured'))
        return
      }
    }
  }

  async submitSingleForm(form, current, button_name, button_value) {
    if (form.dataset.changed) {
      console.log('[step-form] Submit%s form %o', current ? ' current' : '', form)
    } else {
      console.log('[step-form] Skip submit%s unchanged form %o', current ? ' current' : '', form)
      return true
    }

    const frame = form.closest('turbo-frame')
    if (frame) {
      // Submit form in frame as this will not reload the full page
      return await new Promise((resolve, reject) => {
        form.requestSubmit()
        frame.addEventListener('turbo:frame-render', e => {
          let fetchResponse = e.detail.fetchResponse
          console.log('turbo:frame-render', fetchResponse.response.status, fetchResponse)
          resolve(fetchResponse.response.status == 200)
        }, {once: true})
        frame.addEventListener('turbo:submit-end', e => {
          console.log('turbo:submit-end', e.detail)
        }, {once: true})
      })
    }

    if (current) {
      // If current form, submit it directly and let the page reload if needed
      if (button_name) {
        const submit = document.createElement('input')
        submit.type = 'submit'
        submit.name = button_name
        submit.value = button_value
        form.append(submit)
        form.requestSubmit(submit)
      } else {
        form.requestSubmit()
      }
    } else {
      // Submit form outside of a turbo-frame but request to continue edit
      const submit = document.createElement('input')
      submit.type = 'submit'
      submit.name = 'save_and_continue_edit'
      submit.value = 'true'
      form.append(submit)
      form.requestSubmit(submit)
    }
    return true
  }

  get isFormChanged() {
    return Array.from(this.element.querySelectorAll('form'), form => (!!form.dataset.changed)).includes(true)
  }

  confirmClose(){
    if (!this.isFormChanged) return true

    return confirm(I18n.t('front.controllers.step_form.discard_unsaved'))
  }

  unloadWindow(e) {
    if (! this.confirmClose()) {
      event.preventDefault()
    }
  }

  closeForm() {
    if (this.confirmClose()) window.close()
  }

  moveStepItems(){
    // Remove dead items
    for (let item of this.submitAreaTarget.querySelectorAll('.c-step-form__step-item')) {
      let sourceId = item.dataset.sourceLocation
      if (!this.element.querySelector(`#${sourceId}`)) {
        item.remove()
      }
    }

    // Move new items
    for (let stepIdx in this.steps) {
      let step = this.steps[stepIdx]
      for (let item of step.querySelectorAll('.c-make-form__submit-item')) {
        let placeholder = document.createElement('span')
        placeholder.id = 'c-step-form__placeholder-' + Math.random().toString(16).slice(2)
        item.parentElement.insertBefore(placeholder, item)
        item.dataset.sourceLocation = placeholder.id
        item.classList.add('c-step-form__step-item')
        item.classList.add(`c-step-form__step-item--${stepIdx}`)
        item.dataset.action = 'click->step-form#submitClicked'
        if (this.currentStep != stepIdx) {
          item.classList.add('hidden')
        }
        this.submitAreaTarget.insertBefore(item, null)
        this.submitAreaTarget.insertBefore(document.createTextNode(' '), null)
      }
    }
  }

  _showStep(stepIdx) {
    for (let nav of this.navs) {
      nav.classList.remove('active')
    }
    for (let step of this.steps) {
      step.classList.add('hidden')
    }

    for (let nav of this.navs_prev) {
      if (stepIdx == 0) nav.classList.add('invisible')
      else nav.classList.remove('invisible')
    }
    for (let nav of this.navs_next) {
      if (stepIdx >= this.length-1) nav.classList.add('invisible')
      else nav.classList.remove('invisible')
    }

    for (let item of this.element.querySelectorAll('.c-step-form__step-item')) {
      if (item.classList.contains(`c-step-form__step-item--${stepIdx}`)) {
        item.classList.remove('hidden')
      } else {
        item.classList.add('hidden')
      }
    }

    if (this.navs.length > stepIdx) this.navs[stepIdx].classList.add('active')
    if (this.steps.length > stepIdx) this.steps[stepIdx].classList.remove('hidden')
    document.querySelector('.main.panel-body').scrollTop = '0px'

    if (this.hasStepTitleTarget) {
      if (this.steps.length > stepIdx) {
        this.stepTitleTarget.innerHTML = this.navs[stepIdx].querySelector('.text').innerHTML
      } else {
        this.stepTitleTarget.innerHTML = ''
      }
    }

    this.currentStep = stepIdx
  }

  showClickedStep(e){
    e.preventDefault()

    let clicked_nav = e.target.closest('.c-step-form__nav-step-item')
    let stepIdx = this.navs.findIndex(nav => nav == clicked_nav)
    if (stepIdx == -1) return

    this._showStep(stepIdx)
  }

  showPrevStep(e){
    e.preventDefault()
    this._showStep(this.currentStep - 1)
  }

  showNextStep(e){
    e.preventDefault()
    this._showStep(this.currentStep + 1)
  }

  deleteDoubleOption(){
    var options = document.querySelectorAll('#user_company_id option')
    var found = []
    options.forEach(function(option) {
      if ($.inArray(option.value, found) != -1) option.remove()
      found.push(option.value)
    })
  }

}

