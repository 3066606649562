import ToolbarBaseController from '../utils/toolbar_base_controller'
import AdminActions from '../../src/admin/actions.js'

export default class AdminToolbarController extends ToolbarBaseController {
  static targets = [
    'addUser',
    'addCompany',
    'addInstance',

    'editLine',
    'editItem',
    'deleteItem',

    'duplicate',

    'editExpirationDate',
    'editManager',
  ]

  connect() {
    super.connect()

    let act_def = {
      'editLine':           this.editLineTarget,
      'editItem':           this.editItemTarget,
      'deleteItem':         this.deleteItemTarget,
      'duplicate':          this.duplicateTarget,
      'editExpirationDate': this.editExpirationDateTarget,
      'editManager':        this.editManagerTarget,

    }
    let actions = new AdminActions(this, [], {toolbar: false, menu: true})
    actions.updateActions(act_def)
    this.bindActions(actions)
  }
}