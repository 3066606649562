import ContextMenuBaseController from '../utils/context_menu_base_controller'
import AdminActions from '../../src/admin/actions.js'

export default class AdminContextMenuContainerController extends ContextMenuBaseController {
  static targets = [
    'addUser',
    'addDepartment',
    'addCompany',
    'addInstance',
  ]

  connect() {
    super.connect()
    this.addAction('keydown@document', 'handleShortcuts')
    this.updateActions()
  }

  updateActions(){
    this.addUserTarget.setAttribute('href', this._buildMakeUrlAdmin('user', 'new'))
    this.addDepartmentTarget.setAttribute('href', this._buildMakeUrlAdmin('department', 'new'))
    this.addCompanyTarget.setAttribute('href', this._buildMakeUrlAdmin('company', 'new'))
    this.addInstanceTarget.setAttribute('href', this._buildMakeUrlAdmin('instance', 'new'))

    let aa = new AdminActions(this, [], {toolbar: false, menu: true})
    aa.updateActions({
      'addUser':        this.addUserTarget,
      'addDepartment':  this.addDepartmentTarget,
      'addCompany':     this.addCompanyTarget,
      'addInstance':    this.addInstanceTarget,
    })

    this.bindActions(aa)
  }


  handleShortcuts(e){
    if (this.isInputKeydown(e)) return
    this.updateActions()

    if (e.key == 'u' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addUserTarget)
    }

    if (e.key == 'd' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addDepartmentTarget)
    }

    if (e.key == 'c' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addCompanyTarget)
    }

    if (e.key == 'i' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addInstanceTarget)
    }

    return false
  }
}