import TreeviewController from '../utils/treeview_controller'

export default class DesignTreeviewController extends TreeviewController {

  connect(){
    super.connect()
    this.addAction('contextmenu', 'showNodeContextMenu')
  }

  showNodeContextMenu(e){
    return this.getController('design-context-menu').showNodeContextMenu(e)
  }

  _getTreeViewStorageName(treeview){
    treeview = treeview || this.element
    return `design-library-${ Array.prototype.join.call(treeview.classList,'-') }`
  }
}
