import ApplicationController from './../application_controller'

export default class extends ApplicationController {

  static values = {
    'class': String
  }

  connect(){
    super.connect()

    this.addAction('mouseover', 'addClass')
    this.addAction('mouseout', 'removeClass')
  }

  addClass(){
    this.element.classList.add(this.classValue)
  }

  removeClass(){
    this.element.classList.remove(this.classValue)
  }

}


