import ApplicationController from '../application_controller'

export default class AdminFormController extends ApplicationController {

  connect(){
    super.connect()
  }

  generatePassword(){
    var specials = '!@#$%&*()_+{}:<>?\\|[];,./'
    var lowercase = 'abcdefghijklmnopqrstuvwxyz'
    var uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var numbers = '0123456789'
    var all = specials + lowercase + uppercase + numbers
    String.prototype.pick = function(min, max) {
      var n, chars = ''
      if (typeof max === 'undefined') {
        n = min
      } else {
        n = min + Math.floor(Math.random() * (max - min))
      }
      for (var i = 0; i < n; i++) {
        chars += this.charAt(Math.floor(Math.random() * this.length))
      }
      return chars
    }
    // Credit to @Christoph: http://stackoverflow.com/a/962890/464744
    String.prototype.shuffle = function() {
      var array = this.split('')
      var tmp, current, top = array.length
      if (top) while (--top) {
        current = Math.floor(Math.random() * (top + 1))
        tmp = array[current]
        array[current] = array[top]
        array[top] = tmp
      }
      return array.join('')
    }

    var password = (specials.pick(1) + lowercase.pick(2) + uppercase.pick(2) + numbers.pick(2) + all.pick(1, 8)).shuffle()
    document.getElementById('user_password').value = password
  }

  showHidePassword(){
    var x = document.getElementById('user_password')
    if (x.type === 'password') {
      x.type = 'text'
    } else {
      x.type = 'password'
    }
  }

  setExpirationDate(){
    let acticationDate = document.getElementById('user_activation_date').value
    var full_date = new Date(acticationDate)
    var month = full_date.getMonth()
    var year = full_date.getFullYear()
    var expiration_date
    if (month < 6){
      expiration_date = year+'-12-31'
    }
    else {
      year++
      expiration_date = year+'-12-31'
    }
    document.getElementById('user_expiration_date').value = expiration_date
  }

}
