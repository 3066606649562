import ApplicationController from './../application_controller'

export default class extends ApplicationController {

  connect(){
    super.connect()
  }

  reload(){
    this.element.reload()
  }

}

