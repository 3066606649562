window.ap = function(args){
  console.log(args)
}

window.analysisTable = ''

window.stimulus = true

window.turboBlacklist = []

window.allControllerInitialized = 0

// inGroupsOf function
window.inGroupsOf = function(array, number, fillWith) {
  fillWith = fillWith || null
  var index = -number, slices = []

  if (number < 1) return array

  while ((index += number) < array.length) {
    var s = array.slice(index, index + number)
    while (s.length < number)
      s.push(fillWith)
    slices.push(s)
  }
  return slices
}

// Add params to url
window.addParamsToUrl = function(url, params){
  let urlAr = url.split('?')
  let raw_url = urlAr[0]
  let query = urlAr[1]

  let newQuery = Object.entries(params || {}).map((x) => `${x[0]}=${x[1]}`)
  newQuery.push(query)
  return raw_url + '?' + newQuery.join('&')
}

window.tryFunc = function(element, funcName){
  if (element == undefined){
    return undefined
  } else {
    let parenthesis = funcName.slice(-2)
    let name = funcName.slice(0,-2)
    if (parenthesis == '()'){
      return element[name] == undefined ? undefined : element[name]()
    } else {
      return element[funcName]
    }
  }
}

// $.fn.modal.Constructor.Default.keyboard = true
