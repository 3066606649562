// tableview-reorder controller
// ============================
//
// This controller manages reordering items in a tableview
//
// Bound element
// -------------
//
// The controller must be bound on a .t-main element in the following structure:
//
//  .t-main
//    .t-head
//      .t-row
//    .t-body
//      %li.t-row
//        %input{value: ...}
//      %li.t-row
//        ...
//
// .t-row elements can be prevented from reordering if they have the disabled or
// unselectable class set. Each .t-row must contain an input element as direct
// child which value is the row id.
//
// Alternatively, the element structure can be instead:
//
//  .c-tableview
//    .c-tableview__head
//      .c-tableview__row
//    .c-tableview__body
//      %li.c-tableview__row
//        %input{value: ...}
//      %li.c-tableview__row
//        ...
//
// Data parameters
// ---------------
//
// - data-tableview-reorder-url: reorder URL to POST to for the item order
//

import ApplicationController from '../application_controller'
import SelectionMixin from '../concerns/selection_mixin'

export default class TableviewReorderController extends ApplicationController {

  connect(){
    let self = this
    super.connect()

    if (this.element.classList.contains('c-tableview')) {
      this.bodyClass = 'c-tableview__body'
      this.rowClass = 'c-tableview__row'
      // --c-tableview__row--child use an independant ranking and should not be taken into account
      this.inputSelector = '.c-tableview__body .c-tableview__row:not(.--c-tableview__row--child) > input'
      this.draggableClass = 'c-tableview__draggable'
    } else {
      this.bodyClass = 't-body'
      this.rowClass = 't-row'
      this.inputSelector = '.t-body li > input'
      this.draggableClass = 't-draggable'
    }

    // Append dragged item to a separate draggable container that is not
    // constrained by overflow and z-index
    this.draggable = this.element.querySelector(`.${this.draggableClass}`)
    if (!this.draggable) {
      this.draggable = document.createElement('div')
      this.draggable.classList.add(this.bodyClass)
      this.draggable.classList.add(this.draggableClass)
      this.element.appendChild(this.draggable)
    }

    // Can change order of item with drag and drop
    $(this.element.querySelector(`.${this.bodyClass}`)).sortable({
      helper: 'clone',
      appendTo: this.draggable,
      cancel: `.${this.rowClass}.disabled, .${this.rowClass}.unselectable`,
      refreshPositions: true,
      update: (ev, ui) => this.updateOrder(ev, ui),
    }).disableSelection()
  }

  updateOrder(ev, ui){
    let item = ui.item[0]
    if (['Db::Module', 'Db::Flow', 'Db::System'].includes(item.dataset.type)) {
      this.addDbItem(item)
      // When a draggable event is being fired, a sortable event is also fired immediately after.
      // We use this custom class to communicate and stop sorting an element already being taken care of by the draggable library.
    } else if (item.classList.contains('restricted-target-for-draggable')) {
      return
    } else {
      this.sendOrder()
    }
  }

  sendOrder(){
    let inputs = this.element.querySelectorAll(this.inputSelector)
    let order = Array.prototype.map.call(inputs, e => e.getAttribute('value'))
    let types = Array.from(inputs, e => e.getAttribute('data-type'))
    let url = this.data.get('url')

    $.ajax({
      type: 'POST',
      url: url,
      data: { order, types },
      headers: {'x-csrf-token': $('meta[name="csrf-token"]').attr('content')},
      success: function(){ window.turboReload() },
      error: function(err){ alert('Error with order.'); console.warn(err) },
      dataType: 'json'
    })
  }

  addDbItem(item){
    let link = item.querySelector('a')
    item.parentElement.removeChild(item)
    this.getController('design-library').addDbItemFromUrl(link.getAttribute('href'))
  }

}

