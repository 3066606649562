import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'

export default class {

  constructor(id, url, element, options, classes = []){
    this.dispose = this.dispose.bind(this)

    this.frame_id = id
    this.frame_url = url
    this.element = element
    this.options = options
    this.classes = classes

  }

  _create(){
    const popover = new bootstrap.Popover(this.element, {
      trigger: 'manual',
      sanitize: false,
      html: true,
      boundary: 'window',
      ...this.options,
      content: () => {
        let frame = document.createElement('turbo-frame')
        frame.setAttribute('src', this.frame_url)
        frame.setAttribute('id', this.frame_id)
        return frame.outerHTML
      }
    })
    return popover
  }

  dispose(){
    document.body.removeEventListener('click', this.dispose)

    if (this.popover) this.popover.dispose()
    this.popover = null
    return this
  }

  hide(){
    if (this.popover) this.popover.hide()
    return this
  }

  show(){
    this.popover ||= this._create()
    this.popover.show()

    document.body.addEventListener('click', this.dispose)

    const tip = this.popover.getTipElement()
    tip.classList.add('c-popover-frame')
    for (let cls of this.classes){
      tip.classList.add(cls)
    }

    return this
  }

}
