import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  get ancestors(){
    return JSON.parse(this.data.get('ancestors'))
  }

  get current(){
    return this.data.get('current')
  }

}
