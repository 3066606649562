import ApplicationController from './../application_controller'


export default class extends ApplicationController {
  static values = {
    transportTypesWithReturnRate: Array
  }

  static targets = [
    'returnRate'
  ]

  connect(){
    super.connect()
    this.addAction('change', 'showHideReturnRate', this.transportTypeSelect)
    this.showHideReturnRate()
  }

  showHideReturnRate(){
    const with_return_rate = this.transportTypesWithReturnRateValue.includes(this.transportTypeSelect.value)
    this.returnRateTarget.classList.toggle('hidden', !with_return_rate)
    if (with_return_rate) {
      this.restoreValue(this.averageLoadControl)
      this.restoreValue(this.emptyReturnControl)
    } else {
      this.setFixedValue(this.averageLoadControl, 100)
      this.setFixedValue(this.emptyReturnControl, 0)
    }
  }

  restoreValue(control) {
    if (control.dataset.manualValue) {
      console.log('restoreValue', control.dataset.manualValue, control.value, control)
      control.value = control.dataset.manualValue
      delete control.dataset.manualValue
    }
  }

  setFixedValue(control, newVal) {
    control.dataset.manualValue ||= control.value
    control.value = newVal
    console.log('setFixedValue', newVal, control.value, control)
  }

  get transportTypeSelect() {
    return this.element.querySelector('[name="cs_transport_step[transport_type_id]"]')
  }

  get averageLoadControl() {
    return this.element.querySelector('[name="cs_transport_step[average_load_percentage]"]')
  }

  get emptyReturnControl(){
    return this.element.querySelector('[name="cs_transport_step[empty_return_percentage]"]')
  }
}

